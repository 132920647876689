//export const URL = 'http://localhost:3099/';
export const URL = 'https://theater.stagerabbit.com/';

export const IP_KEY = '0f761a30-fe14-11e9-b59f-e53803842572';

export const GET_HEADER = {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' }
      };

export const GET_POST_HEADER = {
        method: 'POST',
        mode: 'cors',
        headers: { 'Content-Type': 'application/json' }
      };

export const makeArray = obj => {
  var arr = Object.keys(obj).map(function(key) {
    return obj[key];
  });
  return arr;
};

export const checkVenue = ( all_venues, pid ) => {
  let this_venue=null;
  let tp = 'production_id';
  if ( all_venues.length > 0 ){
      all_venues.forEach( v => {
          let comp=-null;
          if (v[tp]) {
            comp=v[tp]
          } else if (v[0] && v[0][tp]) {
            comp=v[0][tp];
          }
          if (comp === pid) this_venue=v;
      })
    }

    return this_venue;
}

export const process_submit = el => {
  let body={};
  for (var i = el.length - 1; i >= 0; i--) {
    if (el[i].value && el[i].value !=="0") {
      let id=el[i].id, val=el[i].value;
      body[id]=val;
    }
  }
  return body;
}

export const ALL_THEATERS = `<ul>
<li>11th Hour Theatre Company</li>
<li>16th Street Theater</li>
<li>1st Stage Theater</li>
<li>1st Street Players</li>
<li>246 The Main Cultural Arts Theatre</li>
<li>24th Street Theatare</li>
<li>2nd Act Players</li>
<li>2nd Space Theatre</li>
<li>2nd Stage, Stagecrafters Contemporary Theatre</li>
<li>30 by Ninety Theatre</li>
<li>34 West Theater Company</li>
<li>4 Community Theatre</li>
<li>6th Street Playhouse</li>
<li>7 Stages Theatre</li>
<li>82nd Street Theatre</li>
<li>95 Empire</li>
<li>A Block Off Broadway Community Theatre</li>
<li>A Contemporary Theatre of Connecticut (ACT)</li>
<li>A Noise Within Theatre</li>
<li>A.C.T. Studio Theatre</li>
<li>A.R.T./New York Theatres</li>
<li>ACT 1 Theatre Production Co</li>
<li>ACT I, Inc.</li>
<li>ACT ONE NH</li>
<li>ACT Performing Arts Academy</li>
<li>ACT Theater Company</li>
<li>ACT Theatre</li>
<li>ACT Theatre Company</li>
<li>ACT of Davie</li>
<li>ACT1 Theater</li>
<li>ACTS Theatre Lake Charles</li>
<li>ACTivate Community Through Theatre</li>
<li>ART Station Theatre</li>
<li>ASU Gammage</li>
<li>Abbeville Opera House</li>
<li>Abbey Theater</li>
<li>Aberdeen Community Theatre</li>
<li>Abernethy Performing Arts</li>
<li>Abilene Community Theatre Inc</li>
<li>Abingdon Community Theater</li>
<li>About Face Theatre</li>
<li>Acacia Theatre</li>
<li>Academy Center of the Arts</li>
<li>Academy Park Performing Arts Center</li>
<li>Academy Players</li>
<li>Academy of Music</li>
<li>Academy of Performing Arts</li>
<li>Acadia Repertory Theatre</li>
<li>Acme Theater</li>
<li>Acrosstown Repertory Theatre</li>
<li>Act 1 of Benton County</li>
<li>Act II Playhouse</li>
<li>Act Inc</li>
<li>Act One Community Theater</li>
<li>Act Out Theatre Company</li>
<li>Act Two Theatre</li>
<li>Act3 Productions</li>
<li>Acta</li>
<li>Acting Naturally Theatre</li>
<li>Acting Out Kids Community Theatre</li>
<li>Acting Out!</li>
<li>Actor's Express Theatre Company</li>
<li>Actor's Playhouse</li>
<li>Actor's Playhouse</li>
<li>Actor's Theatre of Charlotte</li>
<li>Actors Bridge</li>
<li>Actors Cabaret of Eugene</li>
<li>Actors Conservatory Theatre</li>
<li>Actors Conservatory Theatre</li>
<li>Actors Guild of Lexington</li>
<li>Actors Shakespeare Company</li>
<li>Actors Theatre of Louisville</li>
<li>Actors' Playhouse at the Miracle Theatre</li>
<li>Actors' Playhouse of Georgetown</li>
<li>Actors' Theatre of Columbus</li>
<li>Actors' Warehouse</li>
<li>Actors, INC</li>
<li>ActorsNET of Bucks County</li>
<li>Actors’ Repertory Theatre of Idaho</li>
<li>Adaire Theatre</li>
<li>Adams Center Griz Tix</li>
<li>Adams Mystery Playhouse</li>
<li>Adams Theatre & Video</li>
<li>Admiral Theatre</li>
<li>Adobe Theater</li>
<li>Adventure Stage Chicago</li>
<li>Adventure Theatre MTC</li>
<li>African American Performing Arts Center</li>
<li>AfterWork Theater</li>
<li>AfterWork Theater</li>
<li>AfterWork Theater</li>
<li>Ahwatukee Childrens Theatre</li>
<li>Aiken Community Theatre at the AECOM Center for the Performing Arts</li>
<li>Airedale Theatre</li>
<li>Akron Civic Theatre</li>
<li>Al. Ringling Theatre</li>
<li>Alabama Shakespeare Festival</li>
<li>Alaska Junior Theatre</li>
<li>Alaska Theatre of Youth</li>
<li>Albany Civic Theater</li>
<li>Albany Park Theater Project</li>
<li>Albert Lea Community Theatre</li>
<li>Alberta Bair Theater</li>
<li>Albright Community Theatre</li>
<li>Albuquerque Little Theatre</li>
<li>Alden Theatre</li>
<li>Aldersgate Church Community Theater</li>
<li>Algonquin Arts Theatre</li>
<li>Alhambra Theater</li>
<li>All An Act Theatre Productions</li>
<li>All Community Theater</li>
<li>All-of-us Express Children's Theatre</li>
<li>Allen Elizabethan Theatre</li>
<li>Allen's Community Theatre</li>
<li>Allendale Civic Theatre</li>
<li>Allentown Public Theatre</li>
<li>Alliance Theatre</li>
<li>Alliance of Resident Theatres</li>
<li>Aloha Performing Arts Co</li>
<li>Alpena Civic Theatre</li>
<li>Alpine Community Theater</li>
<li>Alpine Playhouse</li>
<li>Alpine Theatre Project</li>
<li>Altarena Playhouse</li>
<li>Alton Little Theater</li>
<li>Altoona Community Theatre Inc</li>
<li>Altria Theater</li>
<li>Alva Community Theater</li>
<li>Amarillo Little Theatre</li>
<li>Amas Musical Theatre</li>
<li>Amateur Community Theatre of Rowlett</li>
<li>Amelia Community Theatre - Main Stage</li>
<li>American Association of Community Theatre</li>
<li>American Falls Little Theatre</li>
<li>American Repertory Theater</li>
<li>American Stage Festival</li>
<li>American Stage Theatre Company</li>
<li>American Theatre Company</li>
<li>American University Greenberg Theatre</li>
<li>Americana Theatre Company</li>
<li>Ames Center</li>
<li>Amesbury Community Theater</li>
<li>Amil Tellers-Encore Theaters</li>
<li>Amish Country Theater</li>
<li>Amphibian Stage Productions</li>
<li>Anacortes Community Theatre</li>
<li>Anacostia Playhouse</li>
<li>Anchorage Community Theatre (ACT)</li>
<li>And Toto Too Theatre Company</li>
<li>Andria Theatre - Alexandria Area Arts Association</li>
<li>Andromeda Community Theater</li>
<li>Andy's Summer Playhouse</li>
<li>Angelo Civic Theatre</li>
<li>Angels Theatre Company</li>
<li>Ankeny Community Theatre</li>
<li>Ann Arbor Civic Theatre</li>
<li>Annapolis Opera</li>
<li>Annapolis Shakespeare Company</li>
<li>Annapolis Summer Garden Theatre</li>
<li>Annex Theatre</li>
<li>Another Theatre Company</li>
<li>Anthony Bean Community Theater</li>
<li>Apollinaire Theatre Company</li>
<li>Apollo Civic Theatre</li>
<li>Applause Academy MA, Inc</li>
<li>Applause Community Theater</li>
<li>Apple Hill Playhouse</li>
<li>Appomattox Courthouse Theatre</li>
<li>Aprea Theater</li>
<li>Arcadia University Theater</li>
<li>Archbold Community Theater</li>
<li>Arden Theatre Company</li>
<li>Ardmore Little Theatre Inc</li>
<li>Area Community Theatre</li>
<li>Area Community Theatre</li>
<li>Area Stage Company</li>
<li>Arena Players Incorporated</li>
<li>Arena Stage at the Mead Center for American Theater</li>
<li>Argenta Community Theater</li>
<li>Argyros Performing Arts Center</li>
<li>Arizona Broadway Theatre</li>
<li>Arizona Community Theatre</li>
<li>Arizona Community Theatre ACT 1</li>
<li>Arizona Regional Theatre</li>
<li>Arizona Repertory Theatre</li>
<li>Arizona Theatre Company</li>
<li>Arkansas Public Theatre</li>
<li>Arkansas Repertory Theatre</li>
<li>Arkansas Shakespeare Theatre</li>
<li>Arlington Children's Theatre</li>
<li>Arlington Friends of the Drama</li>
<li>Armour Street Theatre</li>
<li>Armstrong Community Theater</li>
<li>Arrow Rock Lyceum Theatre</li>
<li>Art Court Theatre</li>
<li>Art House Productions</li>
<li>Artbarn Community Theater</li>
<li>Arthur Miller Theatre</li>
<li>Artisan Center Theater</li>
<li>Artistic Civic Theatre</li>
<li>Artistry</li>
<li>Artistry Community Theatre</li>
<li>Artists Repertory Theatre</li>
<li>Arts Center at Iowa Western</li>
<li>Arts Center of Coastal Carolina</li>
<li>Arts Center of the Ozarks</li>
<li>Arts Center on 7</li>
<li>Arts Live Theatre</li>
<li>Arts Mission Oak Cliff</li>
<li>Arts in Motion Community Youth Theater Arts Education, Inc.</li>
<li>ArtsEmerson</li>
<li>ArtsView Children's Theater</li>
<li>Arvada Center for the Arts and Humanities</li>
<li>Ascension Community Theatre (A.C.T.)</li>
<li>Asheville Community Theatre</li>
<li>Ashfield Community Theater</li>
<li>Ashland Community Center</li>
<li>Ashland Productions</li>
<li>Ashwaubenon Performing Arts Center</li>
<li>Aspen District Theater</li>
<li>Aspire Community Theatre</li>
<li>Astor Street Opry Company (ASOC)</li>
<li>Astoria Performing Arts Center</li>
<li>Atchison County Memorial Building</li>
<li>Athens Creative Theatre</li>
<li>Athens Theatre</li>
<li>Atlantic Stage</li>
<li>Atlas Theatre</li>
<li>Attic Community Theater</li>
<li>Attic Ensemble Inc</li>
<li>Attic Productions</li>
<li>Attic Theatre</li>
<li>Attleboro Community Theatre</li>
<li>Auburn Area Community Theatre</li>
<li>Auburn Ave Theater</li>
<li>Auburn Performing Arts Center A.P.A.C.</li>
<li>Audience of One Youth Theater</li>
<li>Aumsville Community Theatre</li>
<li>Aurora Arts Theatre</li>
<li>Aurora Community Theater</li>
<li>Aurora Fox</li>
<li>Aurora Theatre</li>
<li>Austin Playhouse</li>
<li>Austin Scottish Rite Theater</li>
<li>Austin Theatre Alliance</li>
<li>Authentic Community Theatre, Inc.</li>
<li>Aux Dog Theatre Nob Hill</li>
<li>Avalon Foundation Inc</li>
<li>Avalon Theatre</li>
<li>Aventura Arts & Cultural Center</li>
<li>Avila University</li>
<li>Ayden Community Theatre</li>
<li>B H Community Theater</li>
<li>B Street Theatre</li>
<li>BAAD! Bronx Academy of Arts and Dance</li>
<li>BDT Stage</li>
<li>BEAT Children's Theatre</li>
<li>BIG ARTS Sanibel</li>
<li>BLUEBARN Theatre</li>
<li>BW Community Arts School</li>
<li>Babcock Theatre</li>
<li>Backdoor Playhouse</li>
<li>Backdoor Theatre</li>
<li>Backstage Theatre</li>
<li>Bag & Baggage Productions</li>
<li>Bainbridge Little Theatre</li>
<li>Bakersfield Community Theatre</li>
<li>Baldwin City Community Theatre</li>
<li>Baltimore Center Stage</li>
<li>Bam Sales Llc</li>
<li>Band of Toughs</li>
<li>Bangor Community Theatre</li>
<li>Baobab Stage Theatre</li>
<li>Bar J Chuckwagon Suppers</li>
<li>Barbara B. Mann Performing Arts Hall at FSW</li>
<li>Barn Arts</li>
<li>Barn Lot Theater</li>
<li>Barn Playhouse</li>
<li>Barn Theatre</li>
<li>Barnstable Performing Arts Center</li>
<li>Barnstormers</li>
<li>Barnstormers Theatre</li>
<li>Barre Opera House</li>
<li>Barre Players Theater Inc</li>
<li>Barrington Stage Company</li>
<li>Barrow-Civic Theatre</li>
<li>Bartell Theatre</li>
<li>Bartell Theatre Loading Dock /Stage Door</li>
<li>Bartlesville Community Center</li>
<li>Bartlett Theater</li>
<li>Bas Bleu Theatre Company</li>
<li>Bay Area Stage Theatre</li>
<li>Bay City Players Inc</li>
<li>Bay College</li>
<li>Bay Colony Performing Arts Academy</li>
<li>Bay Street Players</li>
<li>Bay Tree Council for the Performing Arts</li>
<li>Bayou Playhouse</li>
<li>Bayside Performing Arts Center</li>
<li>Baytown Little Theater</li>
<li>BeanStalk Community Theatre</li>
<li>Beaumont Community Players</li>
<li>Beaver Dam Community Theatre</li>
<li>Beaver Island Community Center</li>
<li>Beavercreek Community Theatre</li>
<li>Beckwith Theatre Co</li>
<li>Bedford County Players Inc</li>
<li>BeeKay Theatre</li>
<li>Belfast Maskers</li>
<li>Belfry Music Theatre</li>
<li>Bell Road Barn Players</li>
<li>Bellevue Youth Theatre</li>
<li>Bellflower Theatre Co</li>
<li>Belmont Dramatic Club</li>
<li>Belmont University -Trout/Black Box Theatre Complex</li>
<li>Beloit Civic Theater</li>
<li>Beloit Civic Theatre</li>
<li>Bennett Auditorium</li>
<li>Benson Great Hall</li>
<li>Benvenuti Performing Arts Center</li>
<li>Bergen County Players Inc</li>
<li>Bergen Performing Arts Center</li>
<li>Berkeley Community Theater</li>
<li>Berkeley Playhouse</li>
<li>Berkshire Theatre Group</li>
<li>Bertha Frank Performing Arts</li>
<li>Berwick Theater and Center for Community Arts</li>
<li>Betty Long Unruh Theater</li>
<li>Beverly Theatre Guild</li>
<li>Beverly's Terrace Plaza Playhouse</li>
<li>Big Bear Performing Arts Center</li>
<li>Big Noise Theatre</li>
<li>Bigfork Center For Performing Arts</li>
<li>Bigfork Playhouse Children's Theater (the Center)</li>
<li>Bigfork Summer Playhouse</li>
<li>Biloxi Little Theatre</li>
<li>Bing Crosby Theater</li>
<li>Birdsboro Community Theater</li>
<li>Birmingham Children's Theatre</li>
<li>Birmingham Festival Theatre</li>
<li>Bishop Arts Theatre Center</li>
<li>Black Box Theater</li>
<li>Black Box Theater at the Las Vegas Academy of the Arts</li>
<li>Black Box Theatre</li>
<li>Black Box Theatre</li>
<li>Black Cherry Puppet Theater</li>
<li>Black Dirt Theater</li>
<li>Black Ensemble Theater</li>
<li>Black Fedora Comedy Mystery Theatre and Shoppe</li>
<li>Black Rose Theater Company</li>
<li>Black Theatre Troupe Inc</li>
<li>Blackbird Theater</li>
<li>Blackfoot Community Players</li>
<li>Blackfoot Performing Arts Center</li>
<li>Blackout Theatre</li>
<li>Blair Hall Theatre</li>
<li>Blair Theatre</li>
<li>Bloomsburg Theatre Ensemble</li>
<li>Blue Man Group Las Vegas</li>
<li>Blue Mountain Players</li>
<li>Blue Ridge Community Theater</li>
<li>Blue Slipper Theatre</li>
<li>Bluff City Theater</li>
<li>Boch Center - Shubert Theatre</li>
<li>Boch Center - Wang Theatre</li>
<li>Boerne Community Theatre</li>
<li>Boiler Room Theatre</li>
<li>Boise Contemporary Theater</li>
<li>Boise Little Theater</li>
<li>Bologna Performing Arts Center</li>
<li>Bond Street Theatre</li>
<li>Book-It Repertory Theatre</li>
<li>Boone Community Theatre</li>
<li>Booth Tarkington Civic Theatre</li>
<li>Bootless Stageworks</li>
<li>Bordentown Performing Arts Center</li>
<li>Borderlands Theatre</li>
<li>Borough of Manhattan Community College</li>
<li>Boston Center for the Arts</li>
<li>Boston Conservatory at Berklee</li>
<li>Boston Opera House</li>
<li>Boston Playwrights' Theatre</li>
<li>Boulder Ensemble Theatre Company (BETC)</li>
<li>Boulder Fringe Festival</li>
<li>Boulder Theatre</li>
<li>Boulevard Theatre</li>
<li>Bova Productions</li>
<li>Bowen Park Theatre & Opera Co</li>
<li>Bowie Center for the Performing Arts</li>
<li>Bowie Community Theatre</li>
<li>Bowie Playhouse</li>
<li>Bradford Christian Academy</li>
<li>Branch River Theatre</li>
<li>Brandon Youth Theater</li>
<li>Brandywiners Limited</li>
<li>Braumart Theater</li>
<li>Brauntex Performing Arts Theatre</li>
<li>Brave New Workshop Comedy Theatre</li>
<li>Brazosport Center Stages</li>
<li>Bread & Puppet Theatre</li>
<li>Break a Leg Theater Works</li>
<li>Brecksville Theater On the Sq</li>
<li>Brelby Theatre Company</li>
<li>Bremerton Community Theater</li>
<li>Brevard Little Theatre</li>
<li>Brewer Performing Arts Center</li>
<li>Brick Children's Community Theatre</li>
<li>Brick Childrens Comm Theatre</li>
<li>BrickStreet Theatre</li>
<li>Bricolage Production Company</li>
<li>Bridge Players Theatre Company</li>
<li>Brigham's Playhouse, LLC</li>
<li>Brighton Center for the Performing Arts</li>
<li>Brighton's Own Livingston Players</li>
<li>Broadview Heights Spotlights Theater</li>
<li>Broadway At Music Circus</li>
<li>Broadway By the Bay</li>
<li>Broadway Rose Theatre Company</li>
<li>Broadway Sacramento</li>
<li>Broadway Theatre</li>
<li>Broadway Theatre</li>
<li>Broadway Theatre Center</li>
<li>Broadway Theatre League</li>
<li>Broken Arrow Community Playhouse</li>
<li>Brookdale Performing Arts Center</li>
<li>Brookfeld Theatre For the Arts</li>
<li>Brookings Harbor Community Theater</li>
<li>Brooklyn Academy of Music</li>
<li>Brookville Community Theatre</li>
<li>Broom Street Theater</li>
<li>Broward Center for the Performing Arts</li>
<li>Brown Center</li>
<li>Brown Grand Theatre</li>
<li>Brownville Village Theatre</li>
<li>Brownwood's Lyric Theatre</li>
<li>Bruka Theatre</li>
<li>Brundage Park Playhouse</li>
<li>Brunswick Little Theatre</li>
<li>Bryn Athyn Community Theatre</li>
<li>Buchanan Center for the Performing Arts</li>
<li>Buck Creek Players</li>
<li>Bucks County Center for the Performing Arts</li>
<li>Bucks County Playhouse</li>
<li>Bucyrus Little Theatre</li>
<li>Buffalo Community Center</li>
<li>Buffalo Community Theater</li>
<li>Bunce Backyard Productions</li>
<li>Buntport Theater Company</li>
<li>Burbage Theatre Company</li>
<li>Burg Theatre</li>
<li>Burien Actors Theatre</li>
<li>Burning Coal Theatre Company</li>
<li>Burnsville PAC Box Office</li>
<li>Bushfire Theatre of Performing Arts</li>
<li>Butler Little Theatre</li>
<li>Butterfield Stage</li>
<li>Byrnes Performing Arts Center</li>
<li>Byron Civic Theatre</li>
<li>Byron Dick Seaver Theatre Complex</li>
<li>CAST Players</li>
<li>CATCO is Theatre</li>
<li>CCC Stafford Theater</li>
<li>CCD Studio Theatre</li>
<li>CYT Lafayette</li>
<li>Cabot Community Theatre</li>
<li>Cabot School Performing Arts Center (CSPAC)</li>
<li>Cabrillo Playhouse</li>
<li>Cache Theatre Company</li>
<li>Cadence Theatre Company</li>
<li>Cadillac Palace Theatre</li>
<li>Cafe And Then Some</li>
<li>Cailloux Theater</li>
<li>California Actors Theatre</li>
<li>California Theatre of the Performing Arts</li>
<li>Calumet County Community Theatre</li>
<li>Camarillo Skyway Playhouse</li>
<li>Camden Civic Theatre</li>
<li>Camden Community Theatre</li>
<li>Camelot Theatre Co</li>
<li>Camino Real Playhouse</li>
<li>Campanile Center For the Arts</li>
<li>Campbellsville University & Town Hall Productions</li>
<li>Campus Theatre</li>
<li>Candlelight Dinner Playhouse</li>
<li>Canterbury Summer Theatre</li>
<li>Canton Community Theatre</li>
<li>Canyon Theatre Guild</li>
<li>Cape Cod Theatre Company / Harwich Junior Theatre</li>
<li>Cape Cod Theatre Project</li>
<li>Cape Fear Regional Theatre</li>
<li>Cape May Stage</li>
<li>Cape Rep Theatre</li>
<li>Capital City Productions</li>
<li>Capital City Theatre</li>
<li>Capitol Arts Center</li>
<li>Capitol Civic Centre</li>
<li>Capitol Theatre</li>
<li>Capitol Theatre</li>
<li>Capitol Theatre</li>
<li>Cardboard Playhouse Theatre Company</li>
<li>Carl & Glessie Young Auditorium</li>
<li>Carl Freeman Auditorium - Olney Theatre</li>
<li>Carlsbad Comminity Theatre</li>
<li>Carlsbad Community Theatre</li>
<li>Carlsen Center</li>
<li>Carmel Community Playhouse</li>
<li>Carnation City Players</li>
<li>Carnegie Hall</li>
<li>Caroline Community Theatre</li>
<li>Carousel Community Theatre</li>
<li>Carpenter Square Theatre</li>
<li>Carpetbag Theatre Inc</li>
<li>Carriage House Players</li>
<li>Carroll College Theatre</li>
<li>Carroll Community College Theatre</li>
<li>Carrollwood Players Inc</li>
<li>Carson City Community Center</li>
<li>Carson Valley Community Theatre</li>
<li>Carteret Community Theatre</li>
<li>Carver Community Cultural Center</li>
<li>Cary Players Community Theatre Company</li>
<li>Carysbrook Performing Arts Center</li>
<li>Cascades Theatrical Co</li>
<li>Casino Theatre</li>
<li>Cast Theatrical Company, Inc.</li>
<li>Castle Performing Arts Center</li>
<li>Catamount Arts</li>
<li>Catamounts</li>
<li>Catasauqua Area Showcase Theatre</li>
<li>Catholic University Department of Drama</li>
<li>Cats-Community Actor Theatre</li>
<li>Cecil College Young People's Theatre Program</li>
<li>Cedar Falls Community Theatre</li>
<li>Cedarburg Performing Arts Center</li>
<li>Celebration Barn Theater</li>
<li>Center For Living Arts</li>
<li>Center For Performing Arts</li>
<li>Center For the Arts</li>
<li>Center On the Square</li>
<li>Center Players</li>
<li>Center Players Inc</li>
<li>Center REPertory Company</li>
<li>Center Stage</li>
<li>Center Stage Community Theatre</li>
<li>Center Stage Theater</li>
<li>Center Stage Theater</li>
<li>Center Stage Theater</li>
<li>Center Stage Theater - West Shore Community College</li>
<li>Center Stage Theatre</li>
<li>Center Theatre Inc</li>
<li>Center for Art & Theatre</li>
<li>Center for Performing Arts of Luray</li>
<li>Center for the Arts at New Jersey City University</li>
<li>Center for the Arts at Wesley Chapel</li>
<li>CenterPoint Legacy Theatre (Davis Center for the Performing Arts)</li>
<li>CenterStage Clovis Community Theatre</li>
<li>CenterStage In Lake Forest</li>
<li>CenterStage Theatre Company</li>
<li>CenterStage at the JCC</li>
<li>Centerstage Clovis</li>
<li>Centerstage Players Inc</li>
<li>Central Community Theatre</li>
<li>Central Connecticut University Theatre</li>
<li>Central Florida Community Arts</li>
<li>Central Kentucky Community Theatre</li>
<li>Central Lakes Community Performing Arts Center</li>
<li>Central Park Performing Arts Center</li>
<li>Central Square Inc</li>
<li>Central Square Theater</li>
<li>Central Stage Theatre (CSTOCK)</li>
<li>Central Theatre Arts Academy</li>
<li>Central Wisconsin Area Community Theater</li>
<li>Central Wisconsin Children's Theatre</li>
<li>Centre Stage</li>
<li>Centre Theater</li>
<li>Chaffin's Barn Dinner Theatre</li>
<li>Chagrin Valley Little Theatre</li>
<li>Chain Theatre</li>
<li>Chamberlayne Actors Theatre</li>
<li>Chambersburg Community Theatre</li>
<li>Chance Theater</li>
<li>Chandler Center for the Arts</li>
<li>Chandler Hall, Southern Virginia University</li>
<li>Chandler Recital Hall - Diehn Center for Performing Arts</li>
<li>Chanticleer Community Theatre</li>
<li>Chanticleers Theatre</li>
<li>Chanute Community Theatre</li>
<li>Chapel Street Players</li>
<li>Chapin Community Theatre</li>
<li>Chappelear Drama Center</li>
<li>Charles Bender Performing Arts Center</li>
<li>Charles Playhouse</li>
<li>Charles W. Eisemann Center for Performing Arts</li>
<li>Charleston Alley Theatre</li>
<li>Charleston Community Theatre</li>
<li>Charleston Light Opera Guild</li>
<li>Charleston Stage</li>
<li>Charleston Stage Mt. Pleasant Studio</li>
<li>Charlestown Working Theater</li>
<li>Charlotte Players Inc</li>
<li>Chaska Valley Family Theater</li>
<li>Chatfield Center for the Arts</li>
<li>Chatham Drama Guild</li>
<li>Chatham Playhouse</li>
<li>Chattanooga Theatre Centre</li>
<li>Cheatam County Community Theatre</li>
<li>Chelmsford Center for the Arts</li>
<li>Chelsea Theatre Works</li>
<li>Chesapeake Shakespeare Company Theater</li>
<li>Chester Theatre Group</li>
<li>Chestnut Street Playhouse</li>
<li>Chetco Pelican Players</li>
<li>Chicago Children's Theatre</li>
<li>Chickasaw Civic Theatre</li>
<li>Chickasha Community Theatre</li>
<li>Chico Theater Company</li>
<li>Children's Musical Theaterworks</li>
<li>Children's Playhouse of Maryland, Inc.</li>
<li>Children's Theatre Company</li>
<li>Children's Theatre Inc</li>
<li>Children's Theatre of Charlotte</li>
<li>Children's Theatre of Elgin</li>
<li>Children's Theatre of Winnetka</li>
<li>Childrens Educational Theatre</li>
<li>Childrens Musical Theatre</li>
<li>Childrens Theater of Madison</li>
<li>Childrens Theatre of Charleston</li>
<li>Childsplay Theatre Co</li>
<li>Chino Community Theater</li>
<li>Chippewa Valley Theatre Guild</li>
<li>Chocolate Church Arts Center</li>
<li>Chopin Theatre</li>
<li>Christian Youth Theater</li>
<li>Christian Youth Theater</li>
<li>Christian Youth Theater Fredericksburg</li>
<li>Christian Youth Theater North Idaho</li>
<li>Chuck Mathena Center</li>
<li>Church Hill Theatre</li>
<li>Ciccone Theatre</li>
<li>Cinnabar Theater</li>
<li>Circle Arts Theatre</li>
<li>Circle Players Madison Rehearsal Space</li>
<li>Circle Theatre</li>
<li>Circle Theatre</li>
<li>Citadel Theatre</li>
<li>City Circle Acting Company</li>
<li>City Lights Theater Company of San Jose</li>
<li>City Park Players</li>
<li>City Springs Theatre</li>
<li>City Stage Theatre</li>
<li>City Theater</li>
<li>City Theater Company</li>
<li>City Theatre Company</li>
<li>City Theatre of Independence</li>
<li>City of Temecula Youth Musical Theater</li>
<li>Civic Center Music Hall</li>
<li>Civil War Drill Hall Theatre</li>
<li>Clackamas Repertory Theatre</li>
<li>Clague Playhouse Community Theater</li>
<li>Claremont Opera House</li>
<li>Clarence Brown Theatre</li>
<li>Clark State Performing Arts Center</li>
<li>Clark Youth Theatre</li>
<li>Clarksville Community Players</li>
<li>Clarksville Little Theatre</li>
<li>Class Act Musical Theatre</li>
<li>Classical Theatre Company</li>
<li>Clay & Wattles Theatre Company</li>
<li>Clayton Community Theatre</li>
<li>Clear Creek Community Theatre</li>
<li>Clear Space Theatre Company</li>
<li>Clearfield Arts Studio Theatre</li>
<li>Clemson Little Theatre</li>
<li>Clermont Performing Arts Center</li>
<li>Cleveland Public Theatre</li>
<li>Clifton Performance Theatre</li>
<li>Climb Theatre</li>
<li>Clio Cast & Crew Inc</li>
<li>CoHo Productions/CoHo Theatre</li>
<li>Coach House Theater</li>
<li>Coachella Valley Repertory</li>
<li>Coal Creek Theater of Louisville</li>
<li>Coastal Repertory Theatre</li>
<li>Coaster Theatre Playhouse</li>
<li>Coates-Seeligson Theater/Chapel</li>
<li>Cobb County Center for Excellence in the Performing Arts</li>
<li>Cockpit In Court</li>
<li>Cockroach Theatre</li>
<li>Cody Theatre</li>
<li>Coeur d'Alene Summer Theatre</li>
<li>Cohasset Dramatic Club</li>
<li>Cohesion Theatre Company</li>
<li>Colchester Community Theatre</li>
<li>Collaborative Theatre Project</li>
<li>Collective Art School of Tennessee</li>
<li>Collective Face Theatre Ensemble</li>
<li>College Grove Artsitorium</li>
<li>College of Southern Nevada Performing Arts Center</li>
<li>College of the Mainland Community Theatre</li>
<li>Colonial Chorus Players</li>
<li>Colonial Playhouse</li>
<li>Colonial Theatre</li>
<li>Colonial Theatre</li>
<li>Colorado ACTS</li>
<li>Colorado Shakespeare Festival</li>
<li>Colorado Springs Conservatory</li>
<li>Colorado Springs Fine Arts Center</li>
<li>Colorado Stage Company</li>
<li>Colorado State University Thrust Theatre</li>
<li>Columbia Children's Theatre</li>
<li>Columbia Entertainment Co</li>
<li>Columbia Marionette Theatre</li>
<li>Columbia Theatre Association for the Performing Arts</li>
<li>Columbus Association for the Performing Arts</li>
<li>Columbus Civic Theater</li>
<li>Columbus State University - Theatre</li>
<li>Commonweal Theatre Company</li>
<li>Commonwealth Theatre Center</li>
<li>Community Academy of Stage & Theatre</li>
<li>Community Action Agency of Southern New Mexico</li>
<li>Community Actor's Studio Theatre</li>
<li>Community Actor's Theatre</li>
<li>Community Arts Center</li>
<li>Community Asian Theatre</li>
<li>Community College of Vermont (CCV) - Winooski</li>
<li>Community Concert Hall</li>
<li>Community Education Center (CEC)</li>
<li>Community House</li>
<li>Community House Theater</li>
<li>Community Light Opera and Theatre Association</li>
<li>Community Little Theatre</li>
<li>Community Players</li>
<li>Community Players Theatre</li>
<li>Community Players Theatre</li>
<li>Community Players of Concord</li>
<li>Community Players of Greene County</li>
<li>Community Players of Salisbury</li>
<li>Community Playhouse</li>
<li>Community Theater West Hartford</li>
<li>Community Theatre League</li>
<li>Community Theatre Of Little Rock</li>
<li>Community Theatre of Howell</li>
<li>Community Theatre-Clay Co</li>
<li>Community Theatre-Greensboro</li>
<li>Company One</li>
<li>Compass Rose Theater</li>
<li>Comtra Theatre/Comtra Cultural Center</li>
<li>Concord Players Inc</li>
<li>Concord Youth Theatre Inc</li>
<li>Conejo Players Theatre</li>
<li>Conklin's Barn III Dinner Theatre</li>
<li>Connecticut Cabaret Theatre</li>
<li>Connecticut Family Theatre Inc.</li>
<li>Connecticut Playmakers</li>
<li>Connecticut Repertory Theatre</li>
<li>Connecticut Theatre Company</li>
<li>Constellation Theatre Company</li>
<li>Contemporary American Theater Festival</li>
<li>Contra Costa Civic Theatre</li>
<li>Contra Costal Musical Theatre</li>
<li>Convergence Continuum</li>
<li>Cook Forest Sawmill Center for the Arts</li>
<li>Copaken Stage</li>
<li>Corbin Theatre Co.</li>
<li>Corinth Theatre</li>
<li>Corn Stock Theatre</li>
<li>Cornerstone Community Church</li>
<li>Cornerstone Theater Company</li>
<li>Coronado Playhouse</li>
<li>Corvallis Community Theatre</li>
<li>Corvallis School District Theaters</li>
<li>Costa Mesa Playhouse</li>
<li>Cottage Theatre</li>
<li>Council for the Performing Arts</li>
<li>Country Gate Players</li>
<li>Country Gate Players</li>
<li>Country Players</li>
<li>Countryside Community Theatre</li>
<li>Covedale Center For the Performing Arts</li>
<li>Covey Center for the Arts</li>
<li>Cowan Center for the Arts</li>
<li>Cowles Center</li>
<li>Cranberry Township Community Park</li>
<li>Cranford Dramatic Club Theatre</li>
<li>Craterian Theater at The Collier Center for the Performing Arts</li>
<li>Crawford Theatre Company</li>
<li>Crazy Mountain Productions</li>
<li>Creative Arts Theater</li>
<li>Creative Arts of St Tammany</li>
<li>Creative Kids Playhouse Children's Theatre of Orange County</li>
<li>Creede Repertory Theatre</li>
<li>Cresco Community Theatre</li>
<li>Creske Center Auditorium</li>
<li>Cresson Lake Playhouse Business Office</li>
<li>Crested Butte Mountain Theatre</li>
<li>Crighton Theatre</li>
<li>Cripple Creek Theatre Co.</li>
<li>Cronin Auditorium</li>
<li>Cross Community Players</li>
<li>Crossroads Theatre</li>
<li>Croswell Opera House</li>
<li>Crystal Coast Jamboree</li>
<li>Crystal Theatre</li>
<li>Cultural Arts Center of Charleston</li>
<li>Cultural Center at the Opera House</li>
<li>Cultural Park Theatre</li>
<li>Culver City Public Theatre</li>
<li>Cumberland County Playhouse</li>
<li>Cumberland ETC Ruppel Family Theater</li>
<li>Cumberland Theatre</li>
<li>Curio Theatre Company</li>
<li>Curious Theatre Company</li>
<li>Curtain Call</li>
<li>Curtain Call Community Theater</li>
<li>Curtain Call Playhouse</li>
<li>Curtain Call Theatre</li>
<li>Curtain Call Theatre</li>
<li>Curtain Players</li>
<li>Curtis Theatre</li>
<li>Cushing Community Theatre</li>
<li>Cutler Bros. Productions</li>
<li>Cutting Edge Theater</li>
<li>Cyrano's Theatre Company</li>
<li>DCP Theatre</li>
<li>DCTV</li>
<li>Dahle Performing Arts Center</li>
<li>Dairy Arts Center</li>
<li>Dakota Prairie Regional Center for the Arts</li>
<li>Dakota Stage Ltd</li>
<li>Dallas Theater Center</li>
<li>Dalton Little Theatre</li>
<li>Dana Center-Koonz Theatre</li>
<li>Danville Education, Arts & Cultural Center</li>
<li>Dare To Dream Theatre</li>
<li>Darien Arts Center</li>
<li>Darkhorse Theatre</li>
<li>Dassel-Cokato Performing Arts Center</li>
<li>David A. Straz, Jr. Center for the Performing Arts</li>
<li>Davidson Community Players</li>
<li>Davis Musical Theatre Company (DMTC)</li>
<li>Davis-Newport Theater</li>
<li>Dayton Playhouse</li>
<li>Daytona Playhouse</li>
<li>De Soto Family Theatre</li>
<li>Dean College Main Stage Theater</li>
<li>Deane Center-Performing Arts</li>
<li>Dearmon Creative Art School & Theater Inc</li>
<li>Debra and Kevin Rollins Studio Theatre</li>
<li>Deersville Community Players</li>
<li>Deertrees Theatre</li>
<li>Defunkt Theatre</li>
<li>Del E. Webb Center for the Performing Arts</li>
<li>Delaware Children's Theatre</li>
<li>Delaware Theatre Company</li>
<li>Delray Beach Playhouse</li>
<li>Delta Center Stage Theater</li>
<li>Denton Community Theatre</li>
<li>Denver Dangerous Theatre</li>
<li>Denver Performing Arts Complex</li>
<li>Depot Theater Company</li>
<li>Depot Theatre</li>
<li>Derry Opera House</li>
<li>Des Moines Community Playhouse</li>
<li>Desert Ensemble Theatre Company</li>
<li>Desert Foothills Theater</li>
<li>Desert Rose Playhouse</li>
<li>Desert Stages Theatre</li>
<li>Desert Star Playhouse</li>
<li>Desert Theatre Works</li>
<li>Desoto Family Theatre Business Office</li>
<li>Detroit Opera House</li>
<li>Detroit Public Theatre</li>
<li>Detroit Repertory Theatre</li>
<li>Devonshire Playhouse</li>
<li>Diamond Head Theatre</li>
<li>Diana Wortham Theatre</li>
<li>Diane Ballweg Theatre</li>
<li>Dickinson Theatre</li>
<li>Discovery Theater</li>
<li>Diversionary Theatre</li>
<li>Dixon Stage Left</li>
<li>Dock Street Theatre</li>
<li>Dog Story Theater</li>
<li>Dogtown Dance Theatre</li>
<li>Dominion Energy Center</li>
<li>Dominion Stage</li>
<li>Donald C. Bedell Performance Hall</li>
<li>Donald D. Shook Fine Arts Building (FAB)</li>
<li>Dorset Players Inc</li>
<li>Dorset Theatre Festival</li>
<li>Double Edge Theatre</li>
<li>Douglaston Community Theatre</li>
<li>Downey Theatre</li>
<li>Downriver Actors Guild</li>
<li>Downriver Youth Performing Arts Center</li>
<li>Downtown Cabaret Theatre</li>
<li>Downtown Theatre</li>
<li>Dr Richard T Mamiya Theatre</li>
<li>Dr W. W. O'Donnell Performing Arts Center</li>
<li>Dr. Wayne L. Rodehorst Performing Arts Center (PAC)</li>
<li>Dragon Productions Theatre Company</li>
<li>Dragon Theatre Productions</li>
<li>Drama Workshop/Glenmore Playhouse</li>
<li>DramaTech Theatre</li>
<li>Dramashop</li>
<li>Drawbridge Puppet Theatre</li>
<li>DreamWrights Center for Community Arts</li>
<li>Dreamcatcher Repertory Theatre</li>
<li>Dreamland Arts LLC</li>
<li>Dublin Unified School District Center for Performing Arts & Education</li>
<li>Duke Energy Center for the Performing Arts</li>
<li>Duncanville Community Theater</li>
<li>Dundalk Community Theatre</li>
<li>Dunedin Showcase Theater</li>
<li>Dunes Arts Foundation</li>
<li>Dutch Apple Dinner Theatre</li>
<li>Dynamics Community Theater</li>
<li>ECC Arts Center</li>
<li>ELIAS CENTER FOR THE PERFORMING ARTS</li>
<li>Eagle Theatre</li>
<li>Early Bird Dinner Theatre</li>
<li>East Bank Community Theatre</li>
<li>East Central College Theatre</li>
<li>East Haddam Stage Company</li>
<li>East Valley Children's Theatre</li>
<li>Eastbound Theatre</li>
<li>Eastern Oregon Regional Theatre</li>
<li>Eastlight Theatre</li>
<li>Eau Claire Children's Theatre</li>
<li>Ebeyana Studio Theatre</li>
<li>Ebony Repertory Theater</li>
<li>Eccles Theater</li>
<li>Eddy Theatre</li>
<li>Eden Prairie High School Performing Arts Center</li>
<li>Edge Ensemble Theatre</li>
<li>Edgerton Center for the Performing Arts</li>
<li>Edgerton Performing Arts Center</li>
<li>Edison Theatre</li>
<li>Edmonds Driftwood Players</li>
<li>Egyptian Theatre</li>
<li>Egyptian Theatre</li>
<li>Eichelberger Performing Arts Center</li>
<li>El Campanil Theatre</li>
<li>El Centro Su Teatro</li>
<li>El Rey Theater</li>
<li>Eleanor Welch Casey Theatre</li>
<li>Electric City Playhouse</li>
<li>Electric Theater Center</li>
<li>Elements Theatre Company</li>
<li>Elements Theatre Company</li>
<li>Eliot Hall</li>
<li>Elite Theatre Company</li>
<li>Elizabethan Theatre</li>
<li>Elk River Community Theatre</li>
<li>Elkhorn Valley Community Theater</li>
<li>Ellen Eccles Theatre</li>
<li>Ellensburg Theater Co</li>
<li>Ellyn Bye Studio Theater</li>
<li>Elm Street Cultural Arts Village</li>
<li>Elmwood Community Playhouse, Inc.</li>
<li>Elsinore Theatre</li>
<li>Embassy Theatre</li>
<li>Emerald City Players</li>
<li>Emerald Coast Theatre Company</li>
<li>Emergent Arts Company</li>
<li>Emerson Cutler Majestic Theatre</li>
<li>Emerson Paramount Center</li>
<li>Emilyann Theatre</li>
<li>Emmetsburg Mainstreet Community Theatre</li>
<li>Empire Stage</li>
<li>Emporia Granada Theater</li>
<li>Empress Theatre</li>
<li>Enchanted Circle Theater</li>
<li>Encore Performing Arts Center</li>
<li>Encore Stage & Studio</li>
<li>Encore Studio for the Performing Arts, Inc.</li>
<li>Encore Theatre Company</li>
<li>Encore Theatre Company</li>
<li>Encore Theatre Company, Etc</li>
<li>Engelstad Shakespeare Theatre</li>
<li>Engle Lane Theatre</li>
<li>Ennis Public Theatre</li>
<li>Ensemble Theatre Cincinnati</li>
<li>Ensemble Theatre Company</li>
<li>Ensemble Theatre of Chattanooga</li>
<li>Ephrata Performing Arts Center</li>
<li>Epilogue Players Theatre</li>
<li>Epping Community Theater</li>
<li>Equinox Theatre Company</li>
<li>Erie Playhouse</li>
<li>Essential Theatre</li>
<li>Eugene O'Neill Theater Center</li>
<li>Eunice Players' Theatre</li>
<li>Everett Performing Arts Center</li>
<li>Everett Theatre</li>
<li>Evergreen Players</li>
<li>Evergreen Playhouse</li>
<li>Evergreen Productions</li>
<li>Evergreen Theater</li>
<li>Evergreen Theater</li>
<li>Everybody's Theater Company</li>
<li>Everyman Theatre</li>
<li>Excelsior Springs Community Theatre</li>
<li>Exit 82 Studio Theater No reviews</li>
<li>Exit Seven Play Ltd</li>
<li>F Scott Fitzgerald Theatre</li>
<li>FCPAC Theater Loading Dock</li>
<li>Fair Oaks Theatre Festival</li>
<li>Fair Theatre Inc</li>
<li>Fairbanks Light Opera Theatre</li>
<li>Fairbanks Shakespeare Theatre</li>
<li>Fairfield Theatre Company</li>
<li>Fairmont Opera House</li>
<li>Fairview Youth Theatre</li>
<li>Faison Firehouse Theater</li>
<li>Faith Lutheran Chapel & Performing Arts Center</li>
<li>Falcon Theater</li>
<li>Falmouth Theatre Guild</li>
<li>Family Community Theatre</li>
<li>Family Theatre</li>
<li>Fargo Moorhead Community Theatre</li>
<li>Farish Theatre</li>
<li>Farmers Alley Theatre</li>
<li>Farmington Players</li>
<li>Fasset Middle School</li>
<li>Fault Line Theatre</li>
<li>Fault Line Theatre</li>
<li>Fauquier Community Theatre</li>
<li>Fayette County Community Theatre</li>
<li>Fells Point Corner Theatre</li>
<li>Ferndale Repertory Theatre</li>
<li>Ferst Center For The Arts</li>
<li>Festival 56</li>
<li>Fiasco Theater</li>
<li>Fine Arts Guild of the Rockies</li>
<li>Fine Arts Network Theater Company</li>
<li>Fine Linen Theatre</li>
<li>Fire Hall Theatre</li>
<li>Firehouse Arts Center</li>
<li>Firehouse Center for the Arts</li>
<li>Firehouse Community Theatre</li>
<li>Firehouse Theater</li>
<li>Firehouse Theater Company</li>
<li>Firehouse Theatre</li>
<li>Firelech</li>
<li>Firelight Barn Dinner Theater</li>
<li>Fireside Dinner Theatre</li>
<li>First Avenue Playhouse Dessert Theatre</li>
<li>First City Players</li>
<li>First Stage</li>
<li>Fishtank Theatre</li>
<li>Flashback Theater Co.</li>
<li>Flat Earth Theatre</li>
<li>Flat River Community Players</li>
<li>Flat Rock Playhouse</li>
<li>Fleetwood-Jourdain Theatre</li>
<li>Flickinger Center for Performing Arts</li>
<li>Flint Community Players</li>
<li>Florence Little Theatre</li>
<li>Florida Chautauqua Theatre</li>
<li>Florida Children's Theatre</li>
<li>Florida Repertory Theatre</li>
<li>Florida Studio Theatre</li>
<li>Florissant Civic Center Theatre</li>
<li>Flower Mound Performing Arts Theatre</li>
<li>Flowertown Players</li>
<li>Folger Shakespeare Library</li>
<li>Fond Du Lac Community Theatre</li>
<li>Foothills Community Foundation</li>
<li>Foothills Community Theatre</li>
<li>Footlight Players</li>
<li>Footlight Players Theatre</li>
<li>Footlighters, Inc</li>
<li>Ford Community & Performing Arts Center</li>
<li>Ford's Theatre</li>
<li>Forest Roberts Theatre</li>
<li>Forest Theater, Carmel</li>
<li>Forever After Productions</li>
<li>Forever After Productions</li>
<li>Forge Theatre</li>
<li>Fort Defiance Players</li>
<li>Fort Mill Community Playhouse</li>
<li>Fort Peck Theatre</li>
<li>Fort Point Theatre Channel</li>
<li>Fort Smith Little Theatre</li>
<li>Foundry Theatre Inc</li>
<li>Fountain Community Theater</li>
<li>Fountain Hills Theater</li>
<li>Fountain Square Players</li>
<li>Fountain Theatre</li>
<li>Four County Players</li>
<li>Fourth St. Theater</li>
<li>Fox Cities Performing Arts Center</li>
<li>Fox Playhouse</li>
<li>Fox Theatre</li>
<li>Fox Theatre</li>
<li>Fox Theatre</li>
<li>Francis Wilson Playhouse</li>
<li>Franco Center</li>
<li>Frank Theatre</li>
<li>Franklin Opera House Inc</li>
<li>Franklin Performing Arts Company</li>
<li>Frauenthal Center</li>
<li>Frederick LIVE! Theater</li>
<li>Frederick Loewe Theatre at NYU</li>
<li>Fredericksburg Theater Company</li>
<li>Fredericksburg Theatre Ensemble</li>
<li>Fredericktowne Players</li>
<li>Free Street Theater</li>
<li>Freedom Area Community Theatre</li>
<li>Freedom Theatre</li>
<li>Freeport Performing Arts Center</li>
<li>Freeport Players</li>
<li>Fremont Community Theatre</li>
<li>French Creek Community Theatre</li>
<li>Fresno City College: Theatre</li>
<li>Friends For Change Community Amphitheater</li>
<li>Friends of Torrance Theatre Co</li>
<li>Frisco Community Theatre</li>
<li>Front Porch Musical Theatre</li>
<li>Fulton Chapel</li>
<li>Fulton Theatre</li>
<li>Funky Little Theater</li>
<li>Funtime Theater</li>
<li>Furman University Theatre Arts</li>
<li>GALA Hispanic Theatre</li>
<li>GB & Lexi Singh Performance Center</li>
<li>GTS Theatre</li>
<li>Gahanna Lincoln High School Theatre</li>
<li>Gainesville Community Playhouse</li>
<li>Gainesville Theatre Alliance</li>
<li>Galion Community Theatre</li>
<li>Gallery Theater</li>
<li>Gallery Theatre</li>
<li>Galli Theater New York</li>
<li>Garage Theatre</li>
<li>Garcia Opera House</li>
<li>Gard Theatre</li>
<li>Garden Theatre</li>
<li>Garfield Heights Little Theatre</li>
<li>Garner Performing Arts Center</li>
<li>Garrison Players</li>
<li>Garry Marshall Theatre</li>
<li>Gaslight Baker Theatre</li>
<li>Gaslight Theater</li>
<li>Gaslight Theatre</li>
<li>Gateway Players Inc</li>
<li>Geary Theatre</li>
<li>Geauga Lyric Theater Guild</li>
<li>Gem Players</li>
<li>Gem Theater</li>
<li>Generic Theater</li>
<li>Genesis Children's Theatre</li>
<li>Genesius Theatre</li>
<li>George E. Potter Center</li>
<li>George Mason University Center for the Arts</li>
<li>George Street Playhouse</li>
<li>Georgetown Palace Theatre</li>
<li>Georgia Ensemble Theatre</li>
<li>Georgia Southern University Performing Arts Center</li>
<li>Germantown Community Theatre</li>
<li>Germinal Stage</li>
<li>Gerry Ohlendorf Performing Arts Center</li>
<li>Gertrude Krampert Theatre</li>
<li>Gettysburg Community Theatre</li>
<li>Ghost Light Players of MetroWest</li>
<li>Ghostlight Theatre</li>
<li>Gibney 280 Broadway</li>
<li>Gifford Theater</li>
<li>Gilbert Theater</li>
<li>Gladys G. Davis Theatre</li>
<li>Glendale Centre Theatre</li>
<li>Glenview Theatre Guild</li>
<li>Glenwood Vaudeville Revue</li>
<li>Gloucester Stage Company</li>
<li>Golden Chain Theatre</li>
<li>Golden Ticket Productions</li>
<li>Gonda Theatre</li>
<li>Good Company Players</li>
<li>Good Company Theatre</li>
<li>Good Luck Macbeth</li>
<li>Good Theater</li>
<li>Goodman Theatre</li>
<li>Goodspeed Opera House</li>
<li>Goshen Community Theatre Inc</li>
<li>Grace Arts Live</li>
<li>Grafenberg Theater</li>
<li>Granbury Opera House</li>
<li>Granbury Theatre Company</li>
<li>Grand Canyon University</li>
<li>Grand Lake Theatrix Community</li>
<li>Grand Marais Playhouse</li>
<li>Grand Opera House</li>
<li>Grand Prairie Center</li>
<li>Grand Rapids Civic Theatre And School Of Theatre Arts</li>
<li>Grand Theater</li>
<li>Grand Theater: Road CompaNY</li>
<li>Grand Theatre</li>
<li>Grand Theatre at Salt Lake Community</li>
<li>Grandstreet Studio</li>
<li>Grandstreet Theatre</li>
<li>Grant Fine Arts Center</li>
<li>Granville Arts Center; The Atrium</li>
<li>Gratiot County Players Strand Theatre</li>
<li>Greasepaint Youtheatre</li>
<li>Great Bend Community Theatre</li>
<li>Great River Players</li>
<li>Great Theatre</li>
<li>Greater Boston Stage Company</li>
<li>Greater Grand Forks Community Theatre</li>
<li>Greater Lewisville Community Theatre</li>
<li>Green Bay Community Theater</li>
<li>Green Room Theater</li>
<li>Greenbelt Arts Center</li>
<li>Greenbrier Valley Theatre</li>
<li>Greendale Community Theatre</li>
<li>Greensburg Civic Theatre</li>
<li>Greenville Area Community Center</li>
<li>Greenville Area Community Theatre</li>
<li>Greenville Theatre</li>
<li>Greenway Court Theatre</li>
<li>Greenwich House Theater</li>
<li>Greenwood Community Theatre</li>
<li>Greenwood Little Theatre</li>
<li>Gresham Little Theater</li>
<li>Grinnell Community Theatre</li>
<li>Grist Mill Fine Arts Council, Inc</li>
<li>Grosse Pointe Theatre</li>
<li>Grossmont College Theatre</li>
<li>Ground Floor Theatre</li>
<li>Grove Players</li>
<li>Grove Theater Center</li>
<li>Gryphon Theatre</li>
<li>Guadalupe Theater</li>
<li>Gulfport Community Players</li>
<li>Gulfport Little Theatre</li>
<li>Gulfshore Playhouse</li>
<li>Gunston Arts Center, Theater Two</li>
<li>Guthrie Theater</li>
<li>Guymon Community Theatre</li>
<li>HAMILTON PLAYERS</li>
<li>HART Theatre</li>
<li>HCC Central Performing and Visual Arts</li>
<li>HCC Performing Arts Ybor City</li>
<li>HITS Theatre</li>
<li>HUGE Improv Theater</li>
<li>Habersham Community Theatre</li>
<li>Hackmatack Playhouse</li>
<li>Haddonfield Plays & Players</li>
<li>Hagerstown Community Concert</li>
<li>Haines City Community Theatre</li>
<li>Hale Center Theater</li>
<li>Hale Centre Theatre</li>
<li>Hale Centre Theatre - Arizona</li>
<li>Hall Center for the Arts</li>
<li>Hamilton Community Arts Theatre</li>
<li>Hamilton-Gibson Theatre</li>
<li>Hampstead Stage Company</li>
<li>Hancock Performing Arts Center</li>
<li>Hand2Mouth Theatre</li>
<li>Hanover Community Players Inc</li>
<li>Hap Ryder Riverfront Theatre</li>
<li>Harbison Theatre at Midlands Technical College</li>
<li>Harbor Playhouse</li>
<li>Harbor Theatre - Solano Youth Theatre</li>
<li>Harbour Playhouse Inc</li>
<li>Hardin County Playhouse</li>
<li>Hardin County Schools Performing Arts Center</li>
<li>Harford Community College</li>
<li>Harlem Stage</li>
<li>Harlequin Productions</li>
<li>Harlequins Sandusky Community Theatre</li>
<li>Harlingen Community Theatre</li>
<li>Harnett Regional Theatre at Stewart Theater</li>
<li>Harrisburg Shakespeare Company</li>
<li>Hart County Community Theatre</li>
<li>HartBeat Ensemble</li>
<li>Hartford Players, LTD</li>
<li>Hartford Stage</li>
<li>Hartland Players at the Hartland Music Hall</li>
<li>Hartung Theatre</li>
<li>Harvester Performance Center</li>
<li>Harvey Theatre</li>
<li>Harwood Prairie Playhouse</li>
<li>Hastings Community Theatre</li>
<li>Hasty Pudding Theatricals</li>
<li>Hatbox Theatre</li>
<li>Hattiesburg Saenger Theater</li>
<li>Hattiloo Theatre</li>
<li>Hawaii Children's Theatre</li>
<li>Hawaii Opera Theatre</li>
<li>Hawkeye Community Theatre</li>
<li>Hawthorne Players</li>
<li>Hawthorne Theatre</li>
<li>Haymarket Theater, Squires Student Center</li>
<li>Hays CISD Performing Arts Center</li>
<li>Hays Community Theatre</li>
<li>Heartland Men's Chorus</li>
<li>Heartland Theatre</li>
<li>Heartwood Regional Theater</li>
<li>Hedback Community Theatre</li>
<li>Hedgerow Theatre</li>
<li>Heights Players Inc</li>
<li>Heinen Theatre</li>
<li>Helen Connor Laird Theater</li>
<li>Helen F. Spencer Theater</li>
<li>Helen Hocker Theater</li>
<li>Hemlock Theatre Co</li>
<li>Hemsley Theatre</li>
<li>Henderson Civic Theatre</li>
<li>Henderson County Performing Arts Center</li>
<li>Hendersonville Community Theatre</li>
<li>Hendersonville Performing Arts Company</li>
<li>Henegar Center For the Arts</li>
<li>Hennepin Theatre Trust</li>
<li>Henrico County Center For the Arts</li>
<li>Henrico Theatre</li>
<li>Henry County Performing Arts Center</li>
<li>Heritage Center Theater</li>
<li>Heritage Hall Theatre</li>
<li>Heritage Players</li>
<li>Heritage Repertory Theatre</li>
<li>Heritage Theatre</li>
<li>Hershey Area Playhouse</li>
<li>Hershey Theatre</li>
<li>Herter Park Amphitheater</li>
<li>Hi Square InC.</li>
<li>Hickory Community Theatre</li>
<li>High Mountain Community Theater</li>
<li>High Point Community Theatre</li>
<li>High Point Theatre</li>
<li>High Springs Playhouse</li>
<li>High Street Arts Center</li>
<li>High Valley Arts Outdoor Theater</li>
<li>Highland Center For the Arts</li>
<li>Highland Center for the Arts</li>
<li>Highland Park Community Center</li>
<li>Highlands Community Players</li>
<li>Highlands Little Theatre</li>
<li>Highline Performing Art Center</li>
<li>Hiland Theater</li>
<li>Hilde Performance Center</li>
<li>Hill Country Community Theatre</li>
<li>Hillbarn Theatre</li>
<li>Hillman Center for Performing Arts</li>
<li>Hillsboro Artists' Regional Theatre</li>
<li>Hippodrome Theatre</li>
<li>Hippodrome Theatre</li>
<li>Historic Fayette Theatre</li>
<li>Historic Holmes Theatre</li>
<li>Historic Masonic Theatre</li>
<li>Historic Owen Theatre</li>
<li>Historic Playmakers Theatre</li>
<li>Historic Rodgers Theatre</li>
<li>Historic Texas Theatre</li>
<li>Historic Zodiac Playhouse</li>
<li>Historical Opera Theatre</li>
<li>History Theatre</li>
<li>Hobbs Community Playhouse</li>
<li>Hole In the Wall Theatre</li>
<li>Holland Civic Theatre</li>
<li>Holland Theatre</li>
<li>Holly Theatre</li>
<li>Holly Theatre</li>
<li>Holmdel Theatre Company</li>
<li>Home Made Theater</li>
<li>Homeward Bound Theatre Company Inc</li>
<li>Honolulu Theatre for Youth</li>
<li>Hoogland Center For The Arts</li>
<li>Hooker-Dunham Theater & Gallery</li>
<li>Hopebox Theatre</li>
<li>Hopewell Theater</li>
<li>Horizon Theatre Company</li>
<li>Horowitz Center Smith Theatre</li>
<li>Hot Springs Bathhouse Dinner Theatre</li>
<li>Hovey Players Work Shop</li>
<li>Howells Opera House</li>
<li>Howmet Playhouse</li>
<li>Hub Theatre Company of Boston</li>
<li>Hubbardston Community Theater</li>
<li>Hudson Guild Elliott Center</li>
<li>Hudson Guild Theater</li>
<li>Hudson Players</li>
<li>Hudson School Performance Space</li>
<li>Hudson Theatre Ensemble</li>
<li>Hugh R. Copeland Center</li>
<li>Humphreys School of Musical Theatre</li>
<li>Huntington Avenue Theatre</li>
<li>Huntington Theatre Company</li>
<li>Huron Playhouse</li>
<li>Hurrah Players Incorporated</li>
<li>Hutchinson Theatre</li>
<li>IATI Theater</li>
<li>ICT Warehouse Theatre</li>
<li>Iao Theater</li>
<li>Ice House Theater</li>
<li>Idaho Shakespeare Festival</li>
<li>Ikeda Theater</li>
<li>Illinois Wesleyan University School of Theatre Arts</li>
<li>Imagination Stage</li>
<li>Imagination Theater</li>
<li>Imago Theatre</li>
<li>Immigrants' Theatre Project</li>
<li>Impact Theatre</li>
<li>Imperial Theatre</li>
<li>Impossibilities-Magic, Mindreading and Mayhem!</li>
<li>In Tandem Theatre</li>
<li>In the Heart of the Beast</li>
<li>Independence Community Theater</li>
<li>Indie on Main</li>
<li>Innovation Theatre Works</li>
<li>Insight Theatre Company</li>
<li>Inspiration Stage</li>
<li>Inspire Creative</li>
<li>Inspire Theatre</li>
<li>Intar</li>
<li>InterAct Theatre Company</li>
<li>Interlakes Community Auditorium</li>
<li>Interlakes Summer Theatre</li>
<li>International City Theatre</li>
<li>Intiman Theatre</li>
<li>Invisible Theatre Box Office</li>
<li>Ionia Community Theatre Ionia</li>
<li>Ionia Theatre</li>
<li>Iowa City Community Theatre</li>
<li>Iowa Lakes Community College</li>
<li>Iowa River Players</li>
<li>Iowa Stage Theatre Company</li>
<li>Iris Theater</li>
<li>Iron Door Playhouse</li>
<li>Iron Horse Theatre Company</li>
<li>Iron Springs Chateau Dinner Theater</li>
<li>Irondale Center</li>
<li>Irondale Theater</li>
<li>Irving Community Theater</li>
<li>Irvington Town Hall Theater</li>
<li>Island City Stage</li>
<li>Island Players Inc</li>
<li>Ivoryton Playhouse</li>
<li>J. Everett Collins Center for the Performing Arts</li>
<li>J.Peter Adler Studio Theatre</li>
<li>JACK</li>
<li>JAG Productions: African-American Theatre in Vermont</li>
<li>JCC Wool Studio Theatre</li>
<li>JET</li>
<li>Jackson County Community Theatre</li>
<li>Jackson Hole Playhouse</li>
<li>Jackson Theatre Guild</li>
<li>James A Little Theater</li>
<li>James K. Polk Theater</li>
<li>Jamie Foxx Performing Arts Center</li>
<li>Jane Tasch Performing Arts Theatre</li>
<li>Janesville Little Theatre</li>
<li>Janesville Performing Arts Center</li>
<li>Janus Theatre Company</li>
<li>Jasper's Jubilee Theater</li>
<li>Jean's Playhouse</li>
<li>Jeanne B. McCoy Community Center for the Arts</li>
<li>Jefferson Community Theatre</li>
<li>Jefferson Performing Arts Society (JPAS)</li>
<li>Jenison Center for the Arts</li>
<li>Jenkintown Music Theater</li>
<li>Jenny Wiley Theatre</li>
<li>Jenny Wiley Theatre</li>
<li>Jerry Fay Wilhelm Center for the Performing Arts</li>
<li>Jerry's Habima Theatre</li>
<li>Jersey City Theater Center</li>
<li>Jester East Residence Hall</li>
<li>Jesters Dinner Theatre</li>
<li>Jewel Box Theatre</li>
<li>Jewel Box Theatre</li>
<li>Jewish Community Center</li>
<li>Jewish Community Center of Metropolitan Detroit</li>
<li>Jewish Community Centers -Theatre Box Office</li>
<li>Jewish Cultural Arts Theatre</li>
<li>Jo Ann Davidson Theatre, Vern Riffe Center</li>
<li>Joan C. Edwards Performing Arts Center</li>
<li>Joe Jefferson Players</li>
<li>John Paul Theatre</li>
<li>John William Potter Production Studio (DTC)</li>
<li>Johnny Carson School of Theatre and Film</li>
<li>Johnson City Community Theatre</li>
<li>Johnson Hall Theater</li>
<li>Jonesborough Repertory Theatre</li>
<li>Joplin Little Theatre</li>
<li>José Mateo Ballet Theatre</li>
<li>Juanita K. Hammons Hall for the Performing Arts</li>
<li>Judge Storys Theatrical Troupe</li>
<li>Jump-Start Performance Company</li>
<li>Junction City Little Theatre</li>
<li>Juneau-Douglas Little Theatre</li>
<li>Jungle Theater</li>
<li>Jupiter Theatre</li>
<li>Just Off Broadway @ Epiphany</li>
<li>Just Off Broadway Theatre</li>
<li>KAT</li>
<li>KIDS Musical Theater</li>
<li>KTK Productions</li>
<li>Kalamazoo Civic Theatre</li>
<li>Kalamazoo Civic Theatre-Carver Center Studio Theater</li>
<li>Kalamazoo Civic Theatre-Suzanne D Parish Theatre</li>
<li>Kaleidoscope Theatre</li>
<li>Kalita Humphreys Theater</li>
<li>Kanawha Players Theatre</li>
<li>Kankakee Valley Theatre Association</li>
<li>Kansas City Repertory Theatre</li>
<li>Kauai Community College Performance Arts Center</li>
<li>Kauai Community Players</li>
<li>Kauffman Center for the Performing Arts</li>
<li>Kaufman County Civic Theatre</li>
<li>Kaye Playhouse at Hunter College</li>
<li>Kearney Community Theatre</li>
<li>Keith Albee Performing Arts Center</li>
<li>Kelly Strayhorn Theater</li>
<li>Kelsey Theater at Mercer</li>
<li>Kelsey Theatre</li>
<li>Kennedy Theatre</li>
<li>Kennedy Theatre</li>
<li>Kenneth J. Minnaert Center for the Arts</li>
<li>Kenny Leon's True Colors Theatre Company</li>
<li>Kensington Arts Theatre, Inc.</li>
<li>Kensington Theatre</li>
<li>Kent County Theater Guild</li>
<li>Kentucky Repertory Theatre</li>
<li>Kentwood Players Community Theatre</li>
<li>Kepler Theater</li>
<li>Kernersville Little Theatre</li>
<li>Key City Public Theatre</li>
<li>Keyser-Aday Theatre</li>
<li>KidScape Productions</li>
<li>Kidz Kabaret</li>
<li>Kimo Theatre</li>
<li>Kincaid Regional Theatre</li>
<li>Kirkwood Community Center</li>
<li>Kirkwood Theatre Guild</li>
<li>Kismet Arts Studio & Theatre</li>
<li>Kiss Theatre Company</li>
<li>Kitsap Forest Theater</li>
<li>Know Theatre of Cincinnati</li>
<li>Knoxville Children's Theatre</li>
<li>Koger Center for the Arts</li>
<li>Krayon Kids Musical Theatre Company</li>
<li>Krider Performing Arts Center</li>
<li>Kudzu Playhouse</li>
<li>Kumu Kahua Theatre</li>
<li>Kuumba Ensemble</li>
<li>L.E. and Thelma E. Stephens Performing Arts Center</li>
<li>LBCA Community Center</li>
<li>LCCC Playhouse</li>
<li>LOCAL Theater Company</li>
<li>LSU School of Theatre</li>
<li>La Crescent High School Fine Arts Center</li>
<li>La Crosse Community Theatre</li>
<li>La Jolla Playhouse</li>
<li>La Mirada Theater Center</li>
<li>La Verkin City Community Center/Police Station</li>
<li>LaBelle Theatre</li>
<li>LaForge Encore Theatre Company</li>
<li>LaGuardia Performing Arts Center</li>
<li>Lagniappe Theatre Company</li>
<li>Lake Charles Little Theatre</li>
<li>Lake City Community Theatre</li>
<li>Lake City Playhouse</li>
<li>Lake Country Players</li>
<li>Lake Dillon Foundation for the Performing Arts</li>
<li>Lake Dillon Theatre Company</li>
<li>Lake Park Players</li>
<li>Lake Region Children's Community Theatre & Chorus</li>
<li>Lake Superior Community Theatre</li>
<li>Lake Tahoe Shakespeare Festival</li>
<li>Lake Wales Little Theatre</li>
<li>Lake Worth Playhouse</li>
<li>Lakeland Community Theatre</li>
<li>Lakeshore Players Theatre</li>
<li>Lakeside Community Theatre</li>
<li>Lakeside Players</li>
<li>Lakeville Area Arts Center</li>
<li>Lakewood Center for the Arts</li>
<li>Lakewood Playhouse</li>
<li>Lakewood Theater</li>
<li>Lakewood Theatre Co</li>
<li>Lamoille County Players</li>
<li>Lamp-Lite</li>
<li>Lamplighters Music Theatre</li>
<li>Lancaster Community Players</li>
<li>Lancaster Grand Theatre</li>
<li>Lancaster Players Playhouse</li>
<li>Landmark Community Theatre</li>
<li>Lansdowne Theater</li>
<li>Lansing Community College Dart Auditorium</li>
<li>Lantern Theater Company</li>
<li>Laramie Community Theatre</li>
<li>Las Cruces Community Theatre</li>
<li>Las Vegas Little Theatre</li>
<li>Laurel Little Theatre</li>
<li>Laurel Mill Playhouse</li>
<li>Laurens County Community Theatre</li>
<li>Lawton Community Theatre</li>
<li>Le Chat Noir</li>
<li>Le Mars Community Theatre</li>
<li>Le Petit Theatre</li>
<li>Leach Theatre</li>
<li>Lear Theater</li>
<li>Leavenworth Summer Theater Box</li>
<li>Leavenworth Summer Theater Box plays in 2019</li>
<li>Lebanon Community Theatre Inc</li>
<li>Lebanon Opera House</li>
<li>Lebanon Theatre Company</li>
<li>Lee College Performing Arts Center</li>
<li>Lee Theater</li>
<li>Leeds Arts Council, Inc.</li>
<li>Leeds Theatre</li>
<li>Leeward Community College Theatre</li>
<li>Lemon Bay Playhouse</li>
<li>Lensic Performing Arts Center</li>
<li>Leonard Nimoy Thalia at Symphony Space</li>
<li>Levoy Theatre</li>
<li>Lewiston Civic Theatre</li>
<li>Lex-Ham Community Band</li>
<li>Lexington Children's Theatre</li>
<li>Lexington County Arts Association</li>
<li>Liberty Lake Community Theatre</li>
<li>Liberty Performing Arts Theatre</li>
<li>Liberty Theater</li>
<li>Liberty Theatre</li>
<li>Liberty Theatre</li>
<li>Lied Center of Kansas</li>
<li>Life Jacket Theatre Co.</li>
<li>Lifehouse Theater</li>
<li>Lifestage Theatre</li>
<li>Limelight Performing Arts Center</li>
<li>Limelight Theatre</li>
<li>Limelight Youth Theatre</li>
<li>Lincoln Community Playhouse</li>
<li>Lincoln Community Theatre</li>
<li>Lincoln County On-Stage, Inc.</li>
<li>Lincoln Park Performing Arts Center</li>
<li>Lincoln Theater</li>
<li>Lincoln Theatre</li>
<li>Lincoln Theatre</li>
<li>Lincoln Theatre Company</li>
<li>Lindsay Community Theatre</li>
<li>Linkville Playhouse</li>
<li>LionHeart Productions</li>
<li>Lionheart Theatre Company</li>
<li>Lipkin Theatre at Northampton Community College</li>
<li>Little Carver Theatre/Civic Center</li>
<li>Little Church Theater</li>
<li>Little Colonel Playhouse</li>
<li>Little Compton Community Center</li>
<li>Little Firehouse Theatre</li>
<li>Little Fish Theatre</li>
<li>Little Lake Theatre</li>
<li>Little Mountain Community Theatre</li>
<li>Little Theater of Gastonia</li>
<li>Little Theater of Jefferson</li>
<li>Little Theatre</li>
<li>Little Theatre Off Broadway</li>
<li>Little Theatre of Alexandria</li>
<li>Little Theatre of Crittenden</li>
<li>Little Theatre of Fall River</li>
<li>Little Theatre of Manchester</li>
<li>Little Theatre of Mechanicsburg</li>
<li>Little Theatre of Norfolk</li>
<li>Little Theatre of Owatonna</li>
<li>Little Theatre of Stoughton</li>
<li>Little Theatre of Walla Walla</li>
<li>Little Theatre of the Rockies</li>
<li>Little Theatre-Tuscarawas County</li>
<li>Little Theatre-Virginia Beach</li>
<li>Live Action Set</li>
<li>Live Arts</li>
<li>Live Oak Theatre Company</li>
<li>Live Theatre Workshop</li>
<li>Lively Family Amphitheater</li>
<li>Lofte Community Theatre</li>
<li>Lompoc Theatre Project</li>
<li>Long Beach Playhouse</li>
<li>Long Lake Theater & Event Center</li>
<li>Long Wharf Theatre</li>
<li>Longmont Performing Arts Center</li>
<li>Longstreet Theatre</li>
<li>Looking Glass Playhouse</li>
<li>Lookingglass Theatre Company</li>
<li>Los Alamos Little Theater</li>
<li>Los Altos Stage Company</li>
<li>Lost Nation Theater</li>
<li>Love Your City Theater</li>
<li>Loveland Stage Company</li>
<li>Lowden Theater at the Las Vegas Academy of the Arts</li>
<li>Lubbock Community Theatre</li>
<li>Lucas Area Community Theater</li>
<li>Lucky Penny Community Arts Center</li>
<li>Luna Stage Co Inc</li>
<li>Lutcher Theater-Performing Arts</li>
<li>Lydia Mendelssohn Theatre</li>
<li>Lyell B Clay Concert Theatre</li>
<li>Lynna Schmidt Productions</li>
<li>Lyric Arts Main Street Stage</li>
<li>Lyric Live Theater</li>
<li>Lyric Music Theater</li>
<li>Lyric Repertory Company</li>
<li>Lyric Stage Company of Boston</li>
<li>Lyric Theatre</li>
<li>Lyric Theatre</li>
<li>Lyric Theatre</li>
<li>Lyric Theatre Company</li>
<li>Lyric Theatre of Oklahoma</li>
<li>M T Pockets Theatre Co</li>
<li>M&D Playhouse</li>
<li>M&D at Eastern Slope Inn Playhouse</li>
<li>MCC Theatre</li>
<li>MCTP</li>
<li>MECU Neighborhood Event Grants</li>
<li>MHCA - formerly Memorial Hall Center For the Arts</li>
<li>MHCC Theatre</li>
<li>MJ Freed Performing Arts Theater</li>
<li>MMAS Black Box Theater</li>
<li>MOOSE HALL THEATRE COMPANY</li>
<li>Mabel Tainter Center for the Arts</li>
<li>Mabou Mines</li>
<li>Machickanee Players</li>
<li>Macklanburg Playhouse</li>
<li>Macomb Center for the Performing Arts</li>
<li>Macomb Community Theatre</li>
<li>Macon Little Theatre</li>
<li>Mad Cow Theatre</li>
<li>Mad Horse Theatre</li>
<li>Madeleine Wing Adler Theatre</li>
<li>Madhatters Inc</li>
<li>Madison Community Theater</li>
<li>Madison Lyric Stage</li>
<li>Madison Street Theatre</li>
<li>Magenta Theater</li>
<li>Magic Circle Players</li>
<li>Magic Curtain Productions</li>
<li>Magical Starlight Theatre</li>
<li>Magical Theatre Company</li>
<li>Magik Theater</li>
<li>Magnet Theater</li>
<li>Mahaiwe Performing Arts Center</li>
<li>Main Stage Creative Community</li>
<li>Main Street Landing Performing Arts Center</li>
<li>Main Street Players</li>
<li>Main Street Players</li>
<li>Main Street Players</li>
<li>Main Street Theater</li>
<li>Main Street Theater Belton</li>
<li>Main Street Theatre</li>
<li>Main Street Theatre</li>
<li>Main Street Theatre & Dance Alliance</li>
<li>Main Street Youth Theatre</li>
<li>MainStage Irving</li>
<li>MainStreet Warner Stage</li>
<li>Maine State Music Theatre Pickard Theater</li>
<li>Mainstage Classic Theatre</li>
<li>Majestic Theater</li>
<li>Majestic Theater</li>
<li>Majestic Theatre Studios</li>
<li>Malone Hall</li>
<li>Malt House Theatre</li>
<li>Mamai Theatre</li>
<li>Manatee Performing Arts Center</li>
<li>ManeStage Theatre Company</li>
<li>Manhattan Arts Center</li>
<li>Manhattan Theatre Club Inc</li>
<li>Mann Center of North Carolina</li>
<li>Manoa Valley Theatre</li>
<li>Mansfield Center for the Performing Arts</li>
<li>Mansfield Community Playhouse</li>
<li>Maples Repertory Theatre</li>
<li>Maplewood Barn Theatre</li>
<li>Marathon Community Theatre, Inc.</li>
<li>Marblehead Little Theatre</li>
<li>Marcus Performing Arts Center</li>
<li>Margaret L. Jackson Arts Center</li>
<li>Maricopa Community Youth Theatre</li>
<li>Marie W. Heider Center for the Arts</li>
<li>Mariemont Players Inc</li>
<li>Marietta Theatre Company</li>
<li>Marilyn Rodman Performing Arts Center</li>
<li>Marin Theatre Company</li>
<li>Marion Palace Theatre & May Pavilion</li>
<li>Marion Ross Performing Arts Center</li>
<li>Marjorie Lyons Playhouse</li>
<li>Market House Theatre</li>
<li>Marlboro Community Players</li>
<li>Marquis Theatre</li>
<li>Marsh River Theater</li>
<li>Marshall Civic Players</li>
<li>Marshall County Community Theatre</li>
<li>Marshalltown Community Theatre</li>
<li>Marshfield Community Theatre</li>
<li>Martin Theatre</li>
<li>Martinez Campbell Theater</li>
<li>Mary Godfrey Playhouse</li>
<li>Mary Moody Northen Theatre</li>
<li>Maryland Ensemble Theatre</li>
<li>Maryland Heights Community Center</li>
<li>Maryland Theatre</li>
<li>Marywood University Performing Arts Center</li>
<li>Mask & Mirror Community Theatre</li>
<li>Masquerade Theatre</li>
<li>Masquers Playhouse</li>
<li>Massasoit Theater</li>
<li>Master Arts Theatre</li>
<li>Matrix Theatre Company</li>
<li>Matthews Playhouse</li>
<li>Mattie Kelly Arts Center</li>
<li>Maui Academy of Performing Arts</li>
<li>Maui Arts & Cultural Center</li>
<li>Maverick Theater</li>
<li>Maxwell C. King Center for the Performing Arts</li>
<li>May River Theatre Inc</li>
<li>Mayo Performing Arts Center</li>
<li>Mc Cree Theater</li>
<li>McAllister Theatre</li>
<li>McCallum Theatre</li>
<li>McConnellstown Playhouse</li>
<li>McDonald county High School Performing Arts Center</li>
<li>McIntyre Hall Performing Arts & Conference Center</li>
<li>McKinney Performing Arts Center</li>
<li>McKinney Repertory Theatre</li>
<li>McLeod Theater</li>
<li>McPherson Opera House</li>
<li>Mclean Players</li>
<li>Meadow Brook Theatre</li>
<li>Meadville Community Theatre</li>
<li>Media Theater For The Performing Arts</li>
<li>Medical City Lewisville Grand Theater</li>
<li>Medina County Show Biz Co</li>
<li>Mega-Genius Supply Store & IQ HQ</li>
<li>Melon Patch Players Inc</li>
<li>Melvin Theater</li>
<li>Memorial Hall</li>
<li>Memphis Theatre</li>
<li>Mendocino Theatre Company</li>
<li>Menomonie Theater Guild</li>
<li>Meramec Music Theatre</li>
<li>Mercury Theatre Company</li>
<li>Merely Players Community Theatre</li>
<li>Meridian Little Theatre</li>
<li>Meroney Theater, Piedmont Players Theatre</li>
<li>Merrick Theatre and Center for the Arts</li>
<li>Merrill Arts Center</li>
<li>Merrill Community Arts Center, Home of Woodbury Community Theatre</li>
<li>Merrimack Hall Performing Arts</li>
<li>Merrimack Repertory Theatre</li>
<li>Merseles Studios at Jersey City Theater Center</li>
<li>Mesa Encore Theatre</li>
<li>Mesner Puppet Theater</li>
<li>Mesquite Community Theatre</li>
<li>Mesquite Community Theatre</li>
<li>Methacton Community Theater</li>
<li>Metro Theater Company</li>
<li>MetroStage</li>
<li>Metropolis Performing Arts Centre</li>
<li>Metropolitan Ensemble Theatre (MET)</li>
<li>Metropolitan Playhouse</li>
<li>Metropolitan Theatre</li>
<li>Miami Childrens Theater</li>
<li>Michelee Puppets Inc</li>
<li>Michigan Shakespeare Festival Office</li>
<li>Mid Michigan Family Theatre</li>
<li>Mid Ohio Valley Players Inc</li>
<li>Middleburg Community Theatre</li>
<li>Middleton Performing Arts Center</li>
<li>Middleton Players Theatre</li>
<li>Middletown Lyric Theatre</li>
<li>Midland Community Theatre</li>
<li>Midland Community Theatre Inc.</li>
<li>Midvale Main Street Theatre</li>
<li>Midvale Performing Arts Center</li>
<li>Milagro</li>
<li>Milam Community Theater, Inc.</li>
<li>Milburn Stone Theatre</li>
<li>Mile Square Theatre</li>
<li>Milford 2nd St Players</li>
<li>Mill Mountain Theatre</li>
<li>Mill Town Players</li>
<li>Millbrook Community Players, Inc</li>
<li>Millbrook Theatre</li>
<li>Millennium Center for Performing Arts</li>
<li>Miller Center for the Arts</li>
<li>Miller Symphony Hall</li>
<li>Miller Theatre Complex</li>
<li>Millibo Art Theatre</li>
<li>Mills Masquers</li>
<li>Milton Players Inc</li>
<li>Milwaukee Public Theatre</li>
<li>Milwaukee Youth Arts Center</li>
<li>Mimi's Community Theater</li>
<li>Miners Alley Playhouse</li>
<li>Minnesota Folklore Theater</li>
<li>Minnesota Jewish Theatre Co</li>
<li>Minnesota State University Theatre & Dance</li>
<li>Minnetonka Theatre</li>
<li>Misfits Theater Group</li>
<li>Mississippi Bend Players</li>
<li>Missoula Children's Theatre</li>
<li>Missoula Community Theatre</li>
<li>Missouri Street Theatre</li>
<li>Missouri Theatre</li>
<li>Missouri Theatre</li>
<li>Mitby Theater</li>
<li>Mitchell Hall Theatre</li>
<li>Mixed Blood Theatre</li>
<li>Mixed Magic Theatre</li>
<li>Moab Backyard Theater</li>
<li>Moberly Community Theatre Inc</li>
<li>Mobile Theatre Guild</li>
<li>Modern Muse Theatre Company</li>
<li>Monmouth Players</li>
<li>Monroe Actors Stage Company</li>
<li>Monroe Community Players</li>
<li>Monroe Theatre Guild</li>
<li>Monster Box Theater</li>
<li>Montana Actors' Theatre</li>
<li>Monterey Peninsula College Theater</li>
<li>Montgomery County Arts Council</li>
<li>Montgomery Playhouse</li>
<li>Montgomery Theater</li>
<li>Moon Theatre Company</li>
<li>Moonlight Players</li>
<li>Moonlighter Theatre Group</li>
<li>Moonstruck Theater Company</li>
<li>Moore Theatre</li>
<li>Mooresville Community Children's Theatre</li>
<li>Moraine Valley Community College</li>
<li>Morgan Wixson Theatre</li>
<li>Morgantown Theatre Co</li>
<li>Morris Park Players</li>
<li>Morris Theatre Guild Playhouse</li>
<li>Morrison Center</li>
<li>Morton Theatre</li>
<li>Mosaic Theater Company</li>
<li>Mosinee Community Theatre</li>
<li>Moss Performing Arts Center</li>
<li>Mother Lode Theatre</li>
<li>Mother Road Theatre Company</li>
<li>Motor City Youth Theatre</li>
<li>Mounds Theatre</li>
<li>Mount Baker Theatre</li>
<li>Mount Pleasant Community Theatre</li>
<li>Mount Vernon Community Children's Theatre</li>
<li>Mount Wachusett Community College</li>
<li>Mountain Annie's</li>
<li>Mountain Community Theater</li>
<li>Mountain Playhouse</li>
<li>Mouse River Players</li>
<li>Mraz Center for the Performing Arts</li>
<li>Mt. Gretna Playhouse</li>
<li>Mud Creek Players</li>
<li>Muhlenberg Community Theatre</li>
<li>Mulitz-Gudelsky Theatre Lab</li>
<li>Murfreesboro Little Theatre</li>
<li>Murphys Creek Theatre</li>
<li>Murray Park Amphitheater</li>
<li>Murry's Dinner Playhouse</li>
<li>Music Box Dinner Playhouse</li>
<li>Music Theatre Wichita</li>
<li>Music Theatre of Connecticut</li>
<li>Music Theatre of Idaho</li>
<li>Musical Theater Heritage (MTH THEATER)</li>
<li>Musical Theatre Academy of Orange County</li>
<li>Musical Theatre Village</li>
<li>Musical Theatre West</li>
<li>Musical Theatre of Anthem</li>
<li>Musicals at Richter</li>
<li>NOVA Center For The Performing Arts</li>
<li>NSU Theatre</li>
<li>NW Optimist Performing Arts Center</li>
<li>NYC Children’s Theater</li>
<li>Naatak House</li>
<li>Nancy Byng Community Theater</li>
<li>Narberth Community Theatre</li>
<li>NarroWay Productions</li>
<li>Narrows Community Theater</li>
<li>Nashoba Players</li>
<li>Nashua Theatre Guild</li>
<li>Nashville Children's Theatre</li>
<li>Nashville Repertory Theatre</li>
<li>National Alliance Music Theatre</li>
<li>National Black Theatre Inc</li>
<li>National Black Theatre Workshop</li>
<li>National Theatre of the Deaf</li>
<li>Navasota Theatre Alliance</li>
<li>Near West Theatre</li>
<li>Nebraska Association of Community Theatres</li>
<li>Nebraska Repertory Theatre</li>
<li>Nebraska Theatre Caravan</li>
<li>Nebraska Wesleyan University Theatre</li>
<li>Needham Community Theatre Inc</li>
<li>Nemasket River Productions</li>
<li>Neptune Theatre</li>
<li>Nesbitt/Elliot Playhouse</li>
<li>Nevada Ballet Theatre</li>
<li>Neverland Theatre</li>
<li>New Bern Civic Theatre</li>
<li>New Castle Playhouse</li>
<li>New Century Players</li>
<li>New Century Theatre</li>
<li>New Conservatory Theatre Center</li>
<li>New Dawn Theater Company</li>
<li>New England Regional Theater Company</li>
<li>New England Youth Theatre</li>
<li>New Hampshire Performing Arts</li>
<li>New Hampshire Theatre Project</li>
<li>New Heritage Theatre Group</li>
<li>New Horizon Community Theatre</li>
<li>New Jersey Performing Arts Center</li>
<li>New Line Theatre at the Marcelle Theater</li>
<li>New London Barn Playhouse</li>
<li>New Mexico State University Alamogordo</li>
<li>New Mexico Tech Performing Arts Series</li>
<li>New Mexico Young Actors</li>
<li>New Minowa Players</li>
<li>New Moon Theatre</li>
<li>New Players Theater Guild</li>
<li>New Repertory Theatre</li>
<li>New Spire Arts</li>
<li>New Stage Theatre</li>
<li>New Theatre & Restaurant</li>
<li>New Village Arts</li>
<li>New Vision Players</li>
<li>New York City Children’s Theater</li>
<li>New York Shakespeare Exchange</li>
<li>New York Shakespeare Exchange</li>
<li>New York Theatre Workshop</li>
<li>Newbury Musical Theatre Company</li>
<li>Newington Children's Theatre</li>
<li>Newington Mainstage</li>
<li>Newmark Theatre</li>
<li>Newnan Theatre Company</li>
<li>Newport Opera House Association</li>
<li>Newport Performing Arts Center</li>
<li>Newport Playhouse & Cabaret Restaurant</li>
<li>Newport Theatre Arts Center</li>
<li>Newton Community Theatre</li>
<li>Newtowne Players Inc</li>
<li>Next Act Theatre</li>
<li>Next Door Theater</li>
<li>Next Generation Theatre</li>
<li>Next Stage Arts Project</li>
<li>NextStop Theatre Company</li>
<li>Nicholas J. Horn Theatre</li>
<li>Nightingale Theater</li>
<li>Nimbus Theatre</li>
<li>Niswonger Performing Arts Center</li>
<li>Niswonger Performing Arts Center</li>
<li>No Holds Bard</li>
<li>No Square Theatre</li>
<li>None Too Fragile Theatre</li>
<li>NorShor Theatre</li>
<li>North Carolina Stage Company</li>
<li>North Carolina Theatre</li>
<li>North Carolina Theatre Conservatory</li>
<li>North Coast Repertory Theatre</li>
<li>North End Studios</li>
<li>North Georgia Community Players</li>
<li>North Platte Community Playhouse</li>
<li>North Raleigh Arts & Creative Theatre</li>
<li>North River Theatre & Comm Clb</li>
<li>North Shore Center For The Performing Arts</li>
<li>North Shore Music Theatre</li>
<li>North Star Theater Company</li>
<li>North Street Playhouse</li>
<li>North Texas Performing Arts</li>
<li>North Texas Performing Arts - Frisco</li>
<li>Northbrook Theatre</li>
<li>Northeast Alabama Community College</li>
<li>Northeast Wisconsin Passion Play</li>
<li>Northern Sky Theater</li>
<li>Northern Sky Theater - Parking</li>
<li>Northern Sky Theater Amphitheater</li>
<li>Northern Sky Theater Creative Center</li>
<li>Northern Stage</li>
<li>Northern Star Theatre Company</li>
<li>Northern Starz Children's Theatre Company</li>
<li>Northfield Arts Guild Theater</li>
<li>Northglenn Arts</li>
<li>Northland Pioneer College</li>
<li>Northlight Theatre</li>
<li>Northport Community Theater</li>
<li>Northside Theatre Company</li>
<li>Northwest Children's Theater and School</li>
<li>Northwest Washington Theatre Group</li>
<li>Norton Theatre</li>
<li>Novato Theater Company</li>
<li>Nutley Little Theatre Inc</li>
<li>Nylon Fusion Theatre Company</li>
<li>OCCC Visual and Performing Arts Center Theater</li>
<li>Oak Ridge Playhouse</li>
<li>Ocala Civic Theatre</li>
<li>Ocean State Theatre</li>
<li>Oceanside Theatre Company</li>
<li>Oconee Community Theatre</li>
<li>Oconee Playhouse</li>
<li>Oddfellows Playhouse Youth Theater</li>
<li>Odyssey Theatre Ensemble</li>
<li>Off Broadway Theatre Inc.</li>
<li>Off Center Stage</li>
<li>Off Center for the Dramatic Arts</li>
<li>Off Square Theatre Company</li>
<li>Ogunquit Playhouse</li>
<li>Ohio Community Theatre Association</li>
<li>Ohio Star Theater at Dutch Valley</li>
<li>Ohio Theater (Opera House)</li>
<li>Ohio Theatre</li>
<li>Ohio Theatre and Event Center</li>
<li>Ohio Valley Summer Theatre</li>
<li>Ohlook Performing Arts Center</li>
<li>Oil Lamp Theater</li>
<li>Okeechobee Community Theatre</li>
<li>Oklahoma Children's Theatre</li>
<li>Oklahoma City Community College</li>
<li>Oklahoma City Rep</li>
<li>Oklahoma City Theatre Company</li>
<li>Oklahoma Community Theatre</li>
<li>Oklahoma Community Theatre Association</li>
<li>Oklahoma Shakespeare in the Park</li>
<li>Olathe Civic Theatre Association</li>
<li>Old Academy Players</li>
<li>Old Barn Community Theatre</li>
<li>Old Brick Playhouse Co</li>
<li>Old Church Community Theater</li>
<li>Old Church Theater</li>
<li>Old Church Theatre</li>
<li>Old Colony Players Inc</li>
<li>Old Courthouse Theatre</li>
<li>Old Fort Players</li>
<li>Old Library Theatre</li>
<li>Old Log Theatre</li>
<li>Old Post Office Playhouse</li>
<li>Old School Square</li>
<li>Old Town Actors Studio</li>
<li>Old Town Playhouse</li>
<li>Old Town Playhouse Studio Theatre</li>
<li>Old Town Temecula Community Theater</li>
<li>Old Town Theatre</li>
<li>Oldcastle Theatre</li>
<li>Olde Pine Theatre</li>
<li>Olde Towne Hall Theater</li>
<li>Olin Theater</li>
<li>Olney Theatre Center</li>
<li>Olympia Family Theater</li>
<li>Olympia Little Theatre</li>
<li>Olympic Heights Performing Arts Theater</li>
<li>Omaha Community Playhouse</li>
<li>Omaha Performing Arts</li>
<li>On Stage Productions</li>
<li>On Stage Walton</li>
<li>On Stage at Kingsborough</li>
<li>OnStage Playhouse</li>
<li>Onstage Atlanta</li>
<li>Onstage in Bedford</li>
<li>Open Book Theatre Company</li>
<li>Open Door Theatre at New England College</li>
<li>Open Doors</li>
<li>Open Eye Figure Theatre</li>
<li>Open Stage of Harrisburg</li>
<li>OpenStage Theatre & Company, Inc.</li>
<li>Opera Delaware Studios</li>
<li>Opera House City of St George</li>
<li>Opera House Players</li>
<li>Opera House Theatre</li>
<li>Opera House Theatre Company</li>
<li>Opera Theater of Connecticut</li>
<li>Ophelia Theater</li>
<li>Orange Community Players</li>
<li>Orange County Children's Theatre</li>
<li>Orange Park Community Theatre</li>
<li>Ordway Center for the Performing Arts</li>
<li>Oregon Cabaret Theatre</li>
<li>Oregon Children's Theatre</li>
<li>Oregon City Children's Theatre</li>
<li>Oregon Contemporary Theatre</li>
<li>Oregon Shakespeare Festival</li>
<li>Oregon Straw Hat Players</li>
<li>Orion Performing Arts Center</li>
<li>Orlando Repertory Theatre</li>
<li>Orlando Shakes</li>
<li>Ormond Beach Performing Arts</li>
<li>Orphan Girl Children's Theatre</li>
<li>Orphan Girl Theatre</li>
<li>Orpheum Community Hub</li>
<li>Orpheum Theater</li>
<li>Orpheum Theatre</li>
<li>Orpheum Theatre</li>
<li>Orpheum Theatre</li>
<li>Orrville Community Theater</li>
<li>Osceola Arts</li>
<li>Oskaloosa Community Theatre</li>
<li>Other Voices Theatre</li>
<li>Ottumwa Community Players</li>
<li>Ouachita Little Theatre</li>
<li>Our Town Theatre</li>
<li>Out Front on Main, Inc.</li>
<li>Out of Box Theatre</li>
<li>Out of Hand Theater</li>
<li>Out of the Box Community Theatre</li>
<li>Outcast Productions</li>
<li>Outcry Theatre Rehearsal Location</li>
<li>OuterSpace Studios</li>
<li>Outpost 186</li>
<li>Outre Theatre Company</li>
<li>Outvisible Theatre Company</li>
<li>Over Our Head Players</li>
<li>Overtown Performing Arts Center</li>
<li>Overture Center for the Arts</li>
<li>Owen Theatre</li>
<li>Owens Community College Center for Fine & Performing Arts</li>
<li>Owensville Community Theatre</li>
<li>Owosso Community Players</li>
<li>Oxford Area Community Theater</li>
<li>Oxnard College Performing Arts</li>
<li>Oyster Mill Playhouse</li>
<li>Ozark Actors Theatre</li>
<li>Ozark Performing Arts Center</li>
<li>P M & L Theatre</li>
<li>PCPA</li>
<li>PCPA - Pacific Conservatory Theatre</li>
<li>PIX Theater</li>
<li>PTD Productions</li>
<li>PURE Theatre</li>
<li>Pace</li>
<li>Pacific Repertory Theatre</li>
<li>Pacific Resident Theater</li>
<li>Pacifica Spindrift Players</li>
<li>Page Theatre</li>
<li>Pagosa Springs Center for the Arts</li>
<li>Painted Bride Art Center</li>
<li>Palace Playhouse</li>
<li>Palace Theater</li>
<li>Palace Theatre</li>
<li>Palace Theatre</li>
<li>Paliku Theatre</li>
<li>Palm Beach Dramaworks</li>
<li>Palm Beach State College Theater Department</li>
<li>Palm Canyon Theater</li>
<li>Palo Alto Children's Theatre</li>
<li>Palo Alto Players</li>
<li>Pangea World Theater</li>
<li>Panhandle Community Theatre (PCT)</li>
<li>Panola Playhouse</li>
<li>Pantages Theater</li>
<li>Pantages Theatre</li>
<li>Pantagleize Theatre</li>
<li>Pantagleize Theatre Co</li>
<li>Pantochino Productions</li>
<li>Paper Mill Playhouse</li>
<li>Paradise Theatre</li>
<li>Paramount Center for the Arts</li>
<li>Paramount Theater</li>
<li>Paramount Theater - Community Theater</li>
<li>Paramount Theatre</li>
<li>Paramount Theatre</li>
<li>Paramount Theatre</li>
<li>Paramount Theatre</li>
<li>Paris Cabaret/Starline Room Dinner Theatre</li>
<li>Paris Community Theatre</li>
<li>Paris Community Theatre Brown Centre</li>
<li>Parish Players</li>
<li>Park Performing Arts Center</li>
<li>Park Playhouse</li>
<li>Park Square Theatre</li>
<li>Park Theatre</li>
<li>Parker Playhouse</li>
<li>Parkland College Theatre</li>
<li>Parkway Playhouse</li>
<li>Pasadena Little Theater</li>
<li>Pasadena Theatre Co Inc</li>
<li>Passage Theatre Company - Administrative Offices</li>
<li>Passinart Theatre Co</li>
<li>Patio Playhouse Community Theatre</li>
<li>Paul Bunyan Playhouse</li>
<li>Paw Paw Village Players</li>
<li>Payomet Performing Arts Center</li>
<li>Payson Community Theater</li>
<li>Peace Pipe Players Community Theatre</li>
<li>Peacock Players</li>
<li>Pec Playhouse Theatre</li>
<li>Peery's Egyptian Theater</li>
<li>Peg Egan Performing Arts Center</li>
<li>Pembroke Pines Theatre of the Performing Arts</li>
<li>Penasco Theatre</li>
<li>Peninsula Community Theatre</li>
<li>Peninsula Players Theatre</li>
<li>Peninsula Youth Theatre</li>
<li>Penna Playhouse</li>
<li>Pennsylvania Theatre of Performing Arts</li>
<li>Pennsylvania Youth Theatre</li>
<li>Penobscot Theatre Company</li>
<li>Pensacola Little Theatre</li>
<li>Pentacle Theatre</li>
<li>Pentacle Theatre Ticket Office</li>
<li>Penumbra Theatre</li>
<li>People's Light</li>
<li>People's Theatre Project</li>
<li>Peoples Bank Theatre</li>
<li>Peoria Players Theatre</li>
<li>Peppermint Creek Theatre</li>
<li>Performance Now Theatre Company</li>
<li>Performance Space New York</li>
<li>Performing Arts Academy</li>
<li>Performing Arts Center</li>
<li>Performing Arts Center</li>
<li>Performing Arts Center</li>
<li>Performing Arts Center</li>
<li>Performing Arts Center University of Nevada</li>
<li>Performing Arts Center of Wisconsin Rapids</li>
<li>Performing Arts Co Inc</li>
<li>Performing Arts Theatre</li>
<li>Performing Arts at The Theatre at Yellow Springs</li>
<li>Permian Playhouse</li>
<li>Perot Theatre</li>
<li>Perry Players Community Theatre</li>
<li>Perserverance Theatre</li>
<li>Peter Jay Sharp Theatre at Symphony Space</li>
<li>Peter Martin Wege Theatre</li>
<li>Peterborough Players</li>
<li>Petoskey Film Theater</li>
<li>Phamaly Theatre Company</li>
<li>Pharr Community Theater</li>
<li>Philadelphia Street Playhouse</li>
<li>Philadelphia Theatre Company</li>
<li>Phoenix Festival Theater</li>
<li>Phoenix Theater</li>
<li>Phoenix Theater (A Project of Arts Nest)</li>
<li>Phoenix Theatre</li>
<li>Picayune On Stage</li>
<li>Pickleville Playhouse Theatre</li>
<li>Pied Piper Childrens Theater</li>
<li>Piedmont Players Theatre</li>
<li>Pier One Theatre</li>
<li>Pierson Playhouse, Theatre Palisades</li>
<li>Pikes Peak Center For The Performing Arts</li>
<li>Pillsbury House and Theatre</li>
<li>Pin Points Theater</li>
<li>Pinch 'N' Ouch Theatre</li>
<li>Pinckney Players</li>
<li>Pineapple Playhouse</li>
<li>Pinole Community Players</li>
<li>Pintello Comedy Theater</li>
<li>Pioneer Center for the Performing Arts</li>
<li>Pioneer Place On Fifth</li>
<li>Pioneer Theatre Company at Simmons Pioneer Memorial Theatre</li>
<li>Pioneer Underground</li>
<li>Pipestone Performing Arts Center</li>
<li>Pit & Balcony Inc</li>
<li>Pittsburg Community Theatre</li>
<li>Pittsburgh Musical Theater</li>
<li>Piven Theatre</li>
<li>Placer Community Theater</li>
<li>Plain & Fancy Theater</li>
<li>Plainfield Town Hall Opera House</li>
<li>Plan-B Theatre Company</li>
<li>Plant City Entertainment</li>
<li>Planter's Barn Theater</li>
<li>Platte Valley Playhouse</li>
<li>Play Group Theatre</li>
<li>Play Inc. Community Theatre</li>
<li>Play With Your Food</li>
<li>Playcrafters Barn Theatre</li>
<li>Playcrafters Inc</li>
<li>Players By the Sea</li>
<li>Players Club of Swarthmore</li>
<li>Players De Noc</li>
<li>Players Guild Theatre</li>
<li>Players Guild of Dearborn Inc</li>
<li>Players Workshop</li>
<li>Players' Ring</li>
<li>Playhouse 1960</li>
<li>Playhouse 22</li>
<li>Playhouse 38</li>
<li>Playhouse Boise Dinner Theater and Event Center</li>
<li>Playhouse Community Theatre</li>
<li>Playhouse In the Park</li>
<li>Playhouse Merced</li>
<li>Playhouse Smithville</li>
<li>Playhouse South Community Theater</li>
<li>Playhouse Square</li>
<li>Playhouse at the PARC</li>
<li>Playhouse in the Park</li>
<li>Playhouse on Park</li>
<li>Playhouse on the Square</li>
<li>Playmakers Inc</li>
<li>Playmakers of Baton Rouge</li>
<li>Playmasters Theatre Workshop</li>
<li>Playmill Theatre</li>
<li>Plays & Players Theatre</li>
<li>Playtime Productions</li>
<li>Playwrights Local</li>
<li>Playwrights' Round Table</li>
<li>Plaza Main Street Theatre</li>
<li>Plaza Theatre</li>
<li>Plaza Theatre</li>
<li>Plaza Theatre Company at Dudley Hall</li>
<li>Pleasant Grove Players</li>
<li>Pleasant Hill CommunIty Theatre</li>
<li>Pleasanton Playhouse</li>
<li>Plymouth Playhouse</li>
<li>Pocahontas County Opera House</li>
<li>Pocket Sandwich Theatre</li>
<li>Pocket Theatre</li>
<li>Poison Apple Productions</li>
<li>Polish Theatre Institute</li>
<li>Pollard Theatre</li>
<li>Polo Area Community Theatre</li>
<li>Pompano Beach Cultural Center</li>
<li>Popcorn Hat Players</li>
<li>Popcorn Players</li>
<li>Popejoy Hall</li>
<li>Port Aransas Community Theatre</li>
<li>Port Arthur Little Theatre</li>
<li>Port City Playhouse</li>
<li>Port Polson Players Theatre</li>
<li>Port Richmond Theatre Company</li>
<li>Portage Area Community Theatre</li>
<li>Porter Performing Arts Center</li>
<li>Porter Sanford III Performing Arts & Community Center</li>
<li>Porterville's Barn Theater</li>
<li>Porthouse Theatre</li>
<li>Portland Center For Performing</li>
<li>Portland Center Stage at The Armory</li>
<li>Portland Musical Theater Company</li>
<li>Portland Playhouse</li>
<li>Portland Stage</li>
<li>Positive Action Community Theatre</li>
<li>Possum Point Players</li>
<li>Poteet Theatre</li>
<li>Potter Players Community Theatre</li>
<li>PowPAC, Poway''s Community Theatre</li>
<li>PowerStories Theatre</li>
<li>Prairie Center For the Arts</li>
<li>Prairie Fire Children's Theatre</li>
<li>Prairie Lakes Theater</li>
<li>Prairie Players Civic Theatre</li>
<li>Prairie Wind Players</li>
<li>Premiere Stages at Kean University</li>
<li>Prescott Center for the Arts</li>
<li>Preston Arts Center (formerly Henderson Fine Arts Center</li>
<li>Pride Center of Vermont</li>
<li>Prima</li>
<li>Primghar Community Theatre</li>
<li>Prince George's Little Theatre, Inc.</li>
<li>Prince William Little Theatre</li>
<li>Princess City Players</li>
<li>Princess Theatre</li>
<li>Princess Theatre</li>
<li>Princeton Summer Theater</li>
<li>Prior Lake Players Community Theatre</li>
<li>Priscilla Beach Theatre</li>
<li>ProArts</li>
<li>Profile Theatre</li>
<li>Project Drama</li>
<li>Providence Performing Arts Center</li>
<li>Providence Players</li>
<li>Providence Playhouse</li>
<li>Public Theater</li>
<li>Public Theatre of Kentucky</li>
<li>Publick Playhouse</li>
<li>Puerto Rican Traveling Theater (PRTT)</li>
<li>Pull-Tight Players</li>
<li>Pullman Civic Theatre</li>
<li>Pullo Family Performing Arts Center</li>
<li>Puppet Showplace Theater</li>
<li>Purchase Players CPAC</li>
<li>Purple Rose Theatre Company</li>
<li>Puzzle Piece Theatre</li>
<li>Quad-City Music Guild</li>
<li>Quality Hill Playhouse</li>
<li>Quannapowitt Players Inc</li>
<li>QuarryWorks Theater</li>
<li>Queen Creek Performing Arts Center</li>
<li>Queens Theatre</li>
<li>Queensbury Theatre</li>
<li>Quincy Community Theatre</li>
<li>Quogue Community Hall</li>
<li>Quotidian Theatre Co Inc</li>
<li>R-ACT Theatre Productions</li>
<li>RAAC Community Theater</li>
<li>ROYAL Theatre Company</li>
<li>RSCC O'Brien Theatre</li>
<li>RSVP Community Theatre</li>
<li>RTOP (Regional Theatre of the Palouse)</li>
<li>RVCCArts</li>
<li>Rabbit Run Theatre</li>
<li>Racine Theatre Guild</li>
<li>Ragan Theatre</li>
<li>Ragged Edge Community Theater</li>
<li>Rainbow Company Youth Theatre</li>
<li>Raleigh Little Theatre</li>
<li>Raleigh Memorial Auditorium</li>
<li>Ralston Community Theatre</li>
<li>Ramon Theatre</li>
<li>Ramsdell Regional Center for the Arts</li>
<li>Randall Theatre Company</li>
<li>Rattlin' D Playhouse</li>
<li>Ray of Light Theatre</li>
<li>Reading Civic Theatre</li>
<li>Reading Community Players</li>
<li>Reagle Music Theatre of Greater Boston</li>
<li>Red Barn Theatre</li>
<li>Red Barn Theatre</li>
<li>Red Barn Theatre</li>
<li>Red Branch Theatre Company</li>
<li>Red Bull Theater (Administrative Offices)</li>
<li>Red Carpet Community Theatre</li>
<li>Red Curtain Theatre</li>
<li>Red Eye Theater</li>
<li>Red Mask Players</li>
<li>Red Mountain Theatre Company</li>
<li>Redding City Musical Theater</li>
<li>Redlands Footlighters Theatre</li>
<li>Redlands Theatre Festival</li>
<li>Regatta Players</li>
<li>Regent University Theatre</li>
<li>Reif Performing Arts Center</li>
<li>Reilly Theatre</li>
<li>Reitz Theater of CRI</li>
<li>Renaissance Theaterworks</li>
<li>Renaissance Theatre</li>
<li>Renaissance Theatre</li>
<li>Renegade Theater Company</li>
<li>Renner Theatre</li>
<li>Reno Little Theater</li>
<li>Renton Civic Theatre</li>
<li>Repertory Company Theatre Inc</li>
<li>Resident Ensemble Players</li>
<li>Reston Community Players</li>
<li>Revelry Theater</li>
<li>Rex Theatre</li>
<li>Rexburg Community Theatre</li>
<li>Rhino Theatre</li>
<li>Rhode Island Stage Ensemble</li>
<li>Rialto Community Players</li>
<li>Rialto Theater</li>
<li>Rich Heritage of Cedar Rapids Theatre Company</li>
<li>Richard E. Wildish Community Theater</li>
<li>Richard Rodgers Amphitheater</li>
<li>Richardson Theatre Center</li>
<li>Richardson Theatre Centre</li>
<li>Richey Suncoast Theatre</li>
<li>Richland Players</li>
<li>Richmond Community College Bicentennial Amphitheater</li>
<li>Richmond Community Theater</li>
<li>Richmond Community Theatre</li>
<li>Richmond Hill Players</li>
<li>Richmond Triangle Players</li>
<li>Ridgedale Players</li>
<li>Ridgefield Theater Barn</li>
<li>Ritz Community Theater</li>
<li>Ritz Theatre</li>
<li>Rivendell Theatre Ensemble</li>
<li>River Arts Center (Sauk Prairie School District)</li>
<li>River Center- for The Performing Arts</li>
<li>River City Repertory Theatre</li>
<li>River City Theater</li>
<li>River City Theatre Co</li>
<li>River Raisin Centre for the Arts</li>
<li>River Valley Theatre Company</li>
<li>RiverStage Community Theatre</li>
<li>RiverTowne Players</li>
<li>Riverbank Theatre</li>
<li>Riverbelle Dinner Theatre</li>
<li>Riverdale Performing Arts Center</li>
<li>Riverfront Playhouse</li>
<li>Riverside Center for the Performing Arts</li>
<li>Riverside Children's Theatre</li>
<li>Riverside Theatre</li>
<li>Riverside Theatre Works</li>
<li>Rivertown Theaters for the Performing Arts</li>
<li>Riverwalk Theatre</li>
<li>Robert G. Reim Theatre</li>
<li>Robidoux Landing Playhouse</li>
<li>Robidoux Resident Theatre</li>
<li>Robinson Center</li>
<li>Robinson Grand Performing Arts Center</li>
<li>Robsham Theater Arts Center</li>
<li>Rochester Civic Theatre Company</li>
<li>Rochester Opera House</li>
<li>Rochester Repertory Theatre</li>
<li>Rock Hill Community Theater</li>
<li>Rockaway Theatre Company</li>
<li>Rockford University</li>
<li>Rocklin Community Theater</li>
<li>Rockville Little Theatre</li>
<li>Rockville Musical Theatre</li>
<li>Rockwall Community Playhouse</li>
<li>Rocky Hill Community Center</li>
<li>Rocky Mountain Conservatory Theatre: Naperville</li>
<li>Rocky Mountain Repertory Theatre</li>
<li>Rodey Theatre</li>
<li>Rodney And Mary Klett Performing Art Center</li>
<li>Roger Rocka's Dinner Theatre</li>
<li>Rogers Children's Theatre</li>
<li>Rogue Machine Theatre</li>
<li>Rogue Theatre</li>
<li>Rome Little Theatre</li>
<li>Roper Performing Arts Center</li>
<li>Rosa Hart Theatre</li>
<li>Rose Center Theater</li>
<li>Rose Lehrman Arts Center</li>
<li>Rose State College Hudiburg Chevrolet Center</li>
<li>Rose Theatre</li>
<li>Rose Wagner Performing Arts Center</li>
<li>Rosedale Community Player</li>
<li>Rosetown Playhouse</li>
<li>Ross Ragland Theater</li>
<li>Roswell Community Little Theatre</li>
<li>Round House Theatre</li>
<li>Roundtown Players</li>
<li>Roxie Theatre Company</li>
<li>Roxy Regional Theatre</li>
<li>Royal Theatre</li>
<li>Royal Theatre Co</li>
<li>Royall Tyler Theatre</li>
<li>Rubicon Theatre Company</li>
<li>Ruby Theatre</li>
<li>Run Rabbit Run Theatre</li>
<li>Runway Theatre</li>
<li>Rural Cass Community Theatre</li>
<li>Russell Community Theater</li>
<li>Russian River Hall</li>
<li>Ruston Community Theatre</li>
<li>Rylander Theatre</li>
<li>S.T.A.G.E., Inc.</li>
<li>SAGINAW ARTS HUB/K.I.M. Magazine /Riley Media Group/Sojourner Truth Community Theatre</li>
<li>SCF Neel Performing Arts Center</li>
<li>SKyPAC</li>
<li>SPARC - Youth Performing Arts</li>
<li>SRO Theatre Company</li>
<li>STAGES St. Louis</li>
<li>STAGES Youth Theater</li>
<li>STRAND THEATRE</li>
<li>Saco River Grange Hall</li>
<li>Sacramento Theatre Company</li>
<li>Saenger Theater</li>
<li>Saint Lawrence Arts Center</li>
<li>Saint Louis University Theatre</li>
<li>Saint Michael's Playhouse</li>
<li>Salem Community Theatre</li>
<li>Salem's Historic Grand</li>
<li>Salina Community Theatre</li>
<li>Salt Lake Acting Company</li>
<li>Saltworks Theatre Co</li>
<li>Sampson Community Theatre</li>
<li>San Angelo PAC</li>
<li>San Diego City College</li>
<li>San Diego Musical Theatre</li>
<li>San Francisco Playhouse</li>
<li>San Gabriel Mission Playhouse</li>
<li>San Gabriel Valley Music Theatre</li>
<li>San Jose Stage Company</li>
<li>San Juan Community Theatre</li>
<li>Sandbox Theatre</li>
<li>Sandglass Theater</li>
<li>Sandra N. Lloyd Community Center</li>
<li>Sandusky State Theatre</li>
<li>Sandy Actors Theatre</li>
<li>Sandy Springs Performing Arts Center</li>
<li>Santa Fe Playhouse</li>
<li>Santa Monica College Performing Arts Center</li>
<li>Santa Monica Playhouse & Group Theatre</li>
<li>Santa Paula Theatre Center</li>
<li>Sapulpa Community Theatre</li>
<li>Sauk Prairie Theatre Guild</li>
<li>Sauk Theatre</li>
<li>Savannah Children's Theatre</li>
<li>Savannah Repertory Theatre</li>
<li>Savannah Theater</li>
<li>Savannah Theatre</li>
<li>Savoyaires</li>
<li>Saxe Theater</li>
<li>Scene One Theatre</li>
<li>Scenic Theatre</li>
<li>Schaeffer Auditorium</li>
<li>Schaeffer Theatre Box</li>
<li>Schauer Arts Center</li>
<li>Schenectady Civic Playhouse</li>
<li>School Street Playhouse</li>
<li>School of Theatre</li>
<li>Schoolhouse Art Center</li>
<li>Schultz Theatre LLC</li>
<li>Schuster Theatre</li>
<li>Scottfield Theatre Company</li>
<li>Scottsdale Musical Theater Company</li>
<li>Scottsdale School of Film+Theatre at Scottsdale Community College</li>
<li>Scripps Ranch Theatre Website</li>
<li>Seacoast Repertory Theatre</li>
<li>Seattle Musical Theatre at Magnuson Park</li>
<li>Seattle Public Theater</li>
<li>Seattle Rep</li>
<li>Seattle Theatre Group Historic Theatres Library</li>
<li>Seedling Theatre Company</li>
<li>Selah Theatre Project</li>
<li>Sellwood Playhouse</li>
<li>Sentry Theater at 1800</li>
<li>Serenbe Playhouse</li>
<li>Seven Angels Theatre</li>
<li>Seventh Street Playhouse LLC</li>
<li>Shadow Theatre Company</li>
<li>Shaker Bridge Theatre</li>
<li>Shakespeare & Company</li>
<li>Shakespeare Tavern Playhouse</li>
<li>Shakespeare Theatre Company</li>
<li>Shakespeare in the Vines</li>
<li>Shakespeare on the Sound</li>
<li>Shaking the Tree Theatre</li>
<li>Shane Lalani Center for the Arts</li>
<li>Sharon L. Morse Performing Arts Center</li>
<li>Sharon Playhouse</li>
<li>Shaw Center for the Arts</li>
<li>Shawnee Little Theatre Inc</li>
<li>Shawnee Playhouse</li>
<li>Shea Theater Arts Center</li>
<li>Sheboygan Theatre Co</li>
<li>Shelburne Players</li>
<li>Shelby County Community Theatre</li>
<li>Sheldon Theatre</li>
<li>ShenanArts</li>
<li>Shenandoah Summer Music Theatre</li>
<li>Sheridan Civic Theater Guild</li>
<li>Sherman Community Players</li>
<li>Sherman Players</li>
<li>Shine/Pine Hills Community Performing Arts Center</li>
<li>Shoals Community Theatre</li>
<li>ShoeString Community Players</li>
<li>Shoreline Theater Academy</li>
<li>Short North Stage</li>
<li>Shot in the Dark Production</li>
<li>Shotgun Players at the Ashby Stage</li>
<li>Show Boat Community Theater</li>
<li>Showtimers Community Theatre</li>
<li>Shreveport Little Theatre</li>
<li>Shrunken Shakespeare Company</li>
<li>Shubert Theater</li>
<li>Shuler</li>
<li>Sierra Rep's Fallon House Theatre</li>
<li>Sign Design Theatre Co</li>
<li>Signature Productions</li>
<li>Signature Theatre</li>
<li>Sikeston Little Theatre</li>
<li>Silhouette Stages</li>
<li>Silver Spring Stage</li>
<li>Silver Star Theater</li>
<li>Silverthorne Theater Company</li>
<li>Simpich Showcase</li>
<li>Sinatra Park Amphitheater</li>
<li>Sinclair Theatre & Dance Department</li>
<li>Single Carrot Theatre</li>
<li>Sioux City Community Theatre</li>
<li>Sioux Empire Community Theatre</li>
<li>Sixth Street Theatre & Melodrama</li>
<li>Skokie Theatre - Performing Arts Center</li>
<li>Skylight Music Theatre</li>
<li>Skyline Theatre Company</li>
<li>Slant of Light Theater Company</li>
<li>Slidell Little Theatre</li>
<li>Slightly Askew Theatre Ensemble</li>
<li>Slipstream Theatre Initiative</li>
<li>Smith Center</li>
<li>Smith Street Stage</li>
<li>Smith Theater</li>
<li>Smithfield Little Theatre</li>
<li>Smithwick Theatre</li>
<li>Smoky Mountain Community Theater</li>
<li>Sneads Ferry Community Theatre</li>
<li>SoHo Playhouse</li>
<li>Social Circle Theater</li>
<li>Society For the Performing Arts</li>
<li>Sod House Theater</li>
<li>Sojourn Theatre</li>
<li>Sojourner Truth Community Theater</li>
<li>Sol Children Theatre</li>
<li>Solvang Festival Theater</li>
<li>Somerset Valley Players</li>
<li>Sooner Theatre</li>
<li>Soper-Reese Community Theatre</li>
<li>Sottile Theatre - College of Charleston</li>
<li>South Baldwin Community Theatre</li>
<li>South Bay Musical Theatre</li>
<li>South Burlington City Center for the Arts</li>
<li>South Carolina Children's Theatre</li>
<li>South Carolina Theatre Association</li>
<li>South City Theatre</li>
<li>South Milwaukee Performing Arts Center</li>
<li>South Valley Civic Theatre</li>
<li>South of Broadway Theatre Company</li>
<li>Southeast Alabama Community Theatre (SEACT)</li>
<li>Southern Colorado Repertory Theatre</li>
<li>Southern Door Community Auditorium</li>
<li>Southern Kentucky Performing Arts Center (SKyPAC)</li>
<li>Southern Rep Theatre</li>
<li>Southern Theatre</li>
<li>Southgate Community Players</li>
<li>Southington Community Theatre</li>
<li>Southside Theatre Guild</li>
<li>Southwest Iowa Theatre Group</li>
<li>Southwest State University Theatre</li>
<li>Spanish Lyric Theatre</li>
<li>Spark Theater Company</li>
<li>Spartanburg Little Theatre</li>
<li>SpeakEasy Stage Company</li>
<li>Spectrum Dance Theater</li>
<li>Spectrum Theater</li>
<li>Spencer Community Theatre</li>
<li>Spencer Theater for the Performing Arts</li>
<li>Spire Center for the Performing Arts</li>
<li>Spokane Civic Theatre</li>
<li>Spokane Valley Summer Theatre</li>
<li>Spooky Action Theater</li>
<li>Spotlight Children's Theater</li>
<li>Spotlight Players</li>
<li>Spotlight Players</li>
<li>Spotlight Playhouse</li>
<li>Spotlight Productions OKC</li>
<li>Spotlight Theater</li>
<li>Spotlight Theatre Productions</li>
<li>Spotlight Youth Theatre</li>
<li>Spotlighters Theatre</li>
<li>Sprague Community Theatre</li>
<li>Sprague Theatre</li>
<li>Spring Lake Theatre Company</li>
<li>Spring Valley Stagehands</li>
<li>Springer Theatre Academy</li>
<li>Springfield Community Players</li>
<li>Springfield Contemporary Theatre</li>
<li>Springfield Theatre Center</li>
<li>Springhouse Theatre Company</li>
<li>Springs Ensemble Theatre</li>
<li>Springville Community Theater</li>
<li>Spruce Peak Performing Arts Center</li>
<li>Spybird Theater</li>
<li>Square Foot Theatre & Tavern</li>
<li>Square One Theatre Company</li>
<li>Square Top Repertory Theatre</li>
<li>Squires Studio Theatre</li>
<li>St George Theater</li>
<li>St Jean's Players Theatre</li>
<li>St John Theatre</li>
<li>St Louis Black Repertory Co</li>
<li>St Marys Little Theatre</li>
<li>St Vincent Archabbey College: Saint Vincent Summer Theatre</li>
<li>St. Croix Off Broadway Dinner Theatre</li>
<li>St. George Musical Theater</li>
<li>St. George Theatre</li>
<li>St. Louis Actors’ Studio</li>
<li>St. Mane Theatre</li>
<li>St. Petersburg City Theatre</li>
<li>Stadium Theatre</li>
<li>Staenberg-Loup Jewish Community Center</li>
<li>Stage 212</li>
<li>Stage 42</li>
<li>Stage Coach Players</li>
<li>Stage Coach Theatre</li>
<li>Stage Company</li>
<li>Stage Crafters Community Theatre</li>
<li>Stage Door</li>
<li>Stage Door Players</li>
<li>Stage Door Players Theater</li>
<li>Stage Door Productions</li>
<li>Stage Door Productions</li>
<li>Stage Door Repertory Theatre</li>
<li>Stage Door Theater</li>
<li>Stage Door Theatre</li>
<li>Stage Dreams Youth Theater</li>
<li>Stage Faces Theater Company, LLC</li>
<li>Stage III Community Theatre</li>
<li>Stage Left Theater</li>
<li>Stage North Theatre</li>
<li>Stage One Family Theatre</li>
<li>Stage Right!</li>
<li>Stage West Playhouse</li>
<li>Stage West Theatre</li>
<li>StageCenter Community Theatre</li>
<li>StageCoach Theatre Company</li>
<li>StageNorth, Ltd.</li>
<li>StageSource</li>
<li>StageWest Community Theatre</li>
<li>Stagecrafters Baldwin Theatre</li>
<li>Stagecrafters Theater</li>
<li>Stagecrafters Youth Theatre</li>
<li>Stagelight Family Productions</li>
<li>Stageloft Repertory Theater</li>
<li>Stages Theatre Company</li>
<li>Stageworks</li>
<li>Stageworks Northwest</li>
<li>Stageworks Theatre</li>
<li>Stageworks Theatre</li>
<li>Stained Glass Playhouse</li>
<li>Stained Glass Theater</li>
<li>Stained Glass Theater</li>
<li>Stanford Savoyards</li>
<li>Star City Playhouse</li>
<li>Star Theatre</li>
<li>StarStruck Academy & Theatre</li>
<li>StarStruck Theatre</li>
<li>Starkville Community Theatre</li>
<li>Starlight Community Theater</li>
<li>Starlight Dinner Theatre</li>
<li>Starlight Mountain Theatre</li>
<li>Starlight Theatre</li>
<li>Starlight and Studio Theatre</li>
<li>Starlighters II Theatre</li>
<li>Starlighters Performing Arts Center</li>
<li>Stars Theatre Restaurant</li>
<li>State College Community Theatre</li>
<li>State Of Mind Performers & Holly Chin Vocal Studio</li>
<li>State Of Utah: Dan Valentine Auditorium</li>
<li>State Street Theater Co.</li>
<li>State Theater</li>
<li>State Theatre</li>
<li>State Theatre</li>
<li>State Theatre Center for the Arts</li>
<li>State Theatre New Jersey</li>
<li>Station Theater</li>
<li>Station Theatre</li>
<li>Staunton Performing Arts Center</li>
<li>Steel Beam Theatre</li>
<li>Steel City Theatre Company</li>
<li>Steeple Players Theatre</li>
<li>Stefanie H. Weill Center for the Performing Arts</li>
<li>Stella Adler Studio of Acting</li>
<li>SteppingStone Theatre</li>
<li>Sterling Community Theatre</li>
<li>Stillwell Theater at Kennesaw State University</li>
<li>Stockbridge Theatre</li>
<li>Stocker Arts Center</li>
<li>Stockton Civic Theater</li>
<li>Stone Arch Players</li>
<li>Stone's Throw Dinner Theatre</li>
<li>Stories on Stage</li>
<li>Story Theater Company of Ames</li>
<li>Storybook Theatre of Hawaii</li>
<li>Stoughton Opera House</li>
<li>Stow Players</li>
<li>Stowe Theatre Guild</li>
<li>Strand Center Theatre</li>
<li>Strand Theater</li>
<li>Strand Theatre</li>
<li>Stratos G. Dukakis Performing Arts Center</li>
<li>Stratton Players</li>
<li>Strauss Theater Center</li>
<li>Strauss Youth Academy for the Arts</li>
<li>Strawberry Playhouse</li>
<li>Strawdog Theatre Company</li>
<li>Stray Cat Theatre</li>
<li>Stray Dog Theatre</li>
<li>Straz Center</li>
<li>Street Lamp Productions</li>
<li>Street Theatre Company</li>
<li>Strike Theater</li>
<li>Striplight Community Theatre</li>
<li>Strollers Theatre</li>
<li>Strongsville Community Theater</li>
<li>Stroum Jewish Community Center</li>
<li>Struthers Library Theatre</li>
<li>Studio 4 MU Theatre</li>
<li>Studio B Performing Arts Center</li>
<li>Studio Players Inc</li>
<li>Studio Players at The Carriage House Theatre</li>
<li>Studio Tenn</li>
<li>Studio Theatre</li>
<li>Succop Theater</li>
<li>Sudbury Savoyards</li>
<li>Suffield Players</li>
<li>Sullivan Performing Arts Center</li>
<li>Summer Place Theatre</li>
<li>Summer Theatre of New Canaan</li>
<li>SummerStage of Delafield</li>
<li>Summerset Theatre</li>
<li>Summit Theatre</li>
<li>Sumner Community Theater</li>
<li>Sumter Players Inc</li>
<li>Sun City Community Theatre</li>
<li>Sun Prairie Civic Theatre</li>
<li>Sun Prairie High School Performing Arts Center</li>
<li>Sundog Theatre Inc</li>
<li>Sunnyvale Community Players</li>
<li>Sunnyvale Community Players</li>
<li>Sunrise Theatre</li>
<li>Sunset Playhouse</li>
<li>Sunshine Brooks Theater</li>
<li>Super Summer Theatre Studios for the Performing Arts</li>
<li>Surflight Theatre</li>
<li>Surfside Players</li>
<li>Susquehanna Stage Co.</li>
<li>Sutter Creek Theatre</li>
<li>Sutter Street Theatre</li>
<li>Suwanee Valley Players</li>
<li>Swamp Meadow Community Theatre</li>
<li>Swamplight Theater</li>
<li>Swandive Theatre</li>
<li>Sweet Fanny Adams Theatre</li>
<li>Swift Creek Mill Theatre</li>
<li>Swine Palace</li>
<li>Sykesville Little Theatre</li>
<li>Synchronicity Theatre</li>
<li>Synetic Theater</li>
<li>Synetic Theater Rehearsal Studio</li>
<li>TADA! Youth Theater</li>
<li>TAG-The Actors Group</li>
<li>TBA Theater</li>
<li>THE BLACK BOX</li>
<li>THE Hi-LINERS Musical Theatre</li>
<li>THEATREdART</li>
<li>TMCC Nell J. Redfield Foundation Performing Arts Center</li>
<li>TWO's Empress Theater</li>
<li>Tacoma Little Theatre</li>
<li>Tacoma Musical Playhouse</li>
<li>Tada Productions</li>
<li>Take-Up Productions</li>
<li>Talking Horse Productions</li>
<li>Tannery Pond</li>
<li>Tantallon Community Players</li>
<li>Tantrum Theater</li>
<li>Taproot Theatre Company</li>
<li>Taproot Theatre Company's Isaac Studio Theatre</li>
<li>Tarrant Actors Regional Theatre</li>
<li>Tartan Theatre</li>
<li>Tate Hall - Central Piedmont Community College</li>
<li>Tater Patch Players</li>
<li>Tavares Community Theater Company</li>
<li>Tawas Bay Players Inc</li>
<li>Teaneck New Theatre</li>
<li>Teatro De La Luna</li>
<li>Teatro Del Pueblo (office)</li>
<li>Teatro Paraguas</li>
<li>Tecumseh Center for the Arts</li>
<li>Tehachapi Community Theatre Second Space</li>
<li>Tellico Community Playhouse</li>
<li>Temecula Performing Arts Company</li>
<li>Temecula Valley Players</li>
<li>Temple Civic Theatre</li>
<li>Temple Theater for the Performing Arts</li>
<li>Temple Theater-Kings Players'</li>
<li>Tennessee Performing Arts Center</li>
<li>Tennessee Players Inc</li>
<li>Tennessee Williams Theatre</li>
<li>Tennessee Women's Theater Project</li>
<li>Terrific New Theatre</li>
<li>Terry M. Fischer Theater</li>
<li>Tesseract Theatre</li>
<li>Texas Star Dinner Theater</li>
<li>Texas Theatre - Palestine Community Theatre</li>
<li>Thalian Hall</li>
<li>Thanks for the Memories Theatre Company</li>
<li>The 5th Avenue Theatre</li>
<li>The Academy Theatre</li>
<li>The Acting Company</li>
<li>The Acting Ensemble</li>
<li>The Acting Studio Tampa</li>
<li>The Actors Theatre Workshop, Inc.</li>
<li>The Actors' Center</li>
<li>The Alger Community Theater</li>
<li>The Algoma Performing Arts Center</li>
<li>The Alliance of Resident Theatres/New York</li>
<li>The Amato Center for Performing Arts</li>
<li>The American Theatre of Actors Inc</li>
<li>The Antaeus Company</li>
<li>The Arctic Playhouse</li>
<li>The Arlington Players</li>
<li>The Art Centre Theatre</li>
<li>The Arts Center of Cannon County</li>
<li>The Atlanta Opera</li>
<li>The Attic Theater Company</li>
<li>The Auditorium at The Douglass</li>
<li>The Barn Dinner Theatre</li>
<li>The Barn Players Community Theatre</li>
<li>The Barn Theatre</li>
<li>The Barn Theatre</li>
<li>The Barn Theatre</li>
<li>The Barnstormers Theatre</li>
<li>The Barrow Group Theatre Company and School</li>
<li>The Belmont Theatre</li>
<li>The Berman Center For The Performing Arts</li>
<li>The Billie Holiday Theatre</li>
<li>The Box Theatre Co.</li>
<li>The Bradley Playhouse</li>
<li>The Brecksville Theatre</li>
<li>The Brick</li>
<li>The Broadway Lounge</li>
<li>The Brookfield Theatre for the Arts</li>
<li>The Brown Theatre on Broadway</li>
<li>The Bushnell</li>
<li>The Bushwick Starr</li>
<li>The Butte Theater</li>
<li>The Candlelight Theatre</li>
<li>The Cannon Theatre</li>
<li>The Carnegie</li>
<li>The Carolina Theatre</li>
<li>The Carpetbag Theatre, Inc.</li>
<li>The Cassidy Theatre</li>
<li>The Castaways Repertory Theatre</li>
<li>The Center At Eagle Hill</li>
<li>The Center For Arts In Natick</li>
<li>The Center Theater</li>
<li>The Centre at Halifax Community College</li>
<li>The Chalberg Theatre</li>
<li>The Chamber Players</li>
<li>The Chameleon Theatre Circle</li>
<li>The Children's Theatre</li>
<li>The Circle Ensemble Theatre Company</li>
<li>The City Theatre Company</li>
<li>The Clarice Smith Performing Arts Center</li>
<li>The Cloverdale Playhouse</li>
<li>The Cody Cattle Company</li>
<li>The Colonial Center for the Performing Arts</li>
<li>The Colonial Players</li>
<li>The Colonial Theater</li>
<li>The Colonnade Center</li>
<li>The Colony Theatre Company</li>
<li>The Colony at Ogunquit Playhouse</li>
<li>The Columbian Theatre</li>
<li>The Community Players</li>
<li>The Community Players Theater</li>
<li>The Community Playhouse of Lancaster County</li>
<li>The Community Theatre</li>
<li>The Company</li>
<li>The Company Company</li>
<li>The Company Theatre</li>
<li>The Contemporary Theater Company</li>
<li>The Costa Mesa Civic Playhouse</li>
<li>The Coterie Theatre</li>
<li>The Crane Theater</li>
<li>The Crown Center Theatre</li>
<li>The DIO</li>
<li>The Den Theatre</li>
<li>The Dinner Detective Murder Mystery Dinner Show</li>
<li>The Dinner Detective Murder Mystery Dinner Show - Fort Collins</li>
<li>The Dolphin Players</li>
<li>The Downtown Playhouse</li>
<li>The Drama Group</li>
<li>The Duluth Playhouse</li>
<li>The Eagle @ Sugar Hill</li>
<li>The Edge Theatre Company</li>
<li>The El Paso Playhouse</li>
<li>The Elaine Wolf Theatre</li>
<li>The Ellen Theatre</li>
<li>The Empty Space</li>
<li>The Encore Musical Theatre Company</li>
<li>The Factory Theater</li>
<li>The Fenton Village Players</li>
<li>The Firehouse Theatre</li>
<li>The First Act Children's Community Theatre Inc.</li>
<li>The Flying Monkey</li>
<li>The Folly Theater</li>
<li>The Forge Theatre</li>
<li>The Foundation of Arts- Forum Theater</li>
<li>The Fox Theatre</li>
<li>The GEM Theater</li>
<li>The Gallery Players</li>
<li>The George S. and Dore Eccles Center</li>
<li>The Gerold Opera House</li>
<li>The Glitter Box Theater</li>
<li>The Globe of the Great Southwest</li>
<li>The Granada Theatre</li>
<li>The Grand Opera House</li>
<li>The Grand Opera House</li>
<li>The Grand Oshkosh</li>
<li>The Grand Theater</li>
<li>The Grandel</li>
<li>The Grange Playhouse</li>
<li>The Grange Theatre</li>
<li>The Green Room</li>
<li>The Groundhog Day Theater</li>
<li>The Grove Theatre</li>
<li>The Hanover Little Theatre</li>
<li>The Hanover Theatre and Conservatory for the Performing Arts</li>
<li>The Harrell Performing Arts Theatre</li>
<li>The Hartt School</li>
<li>The Hayes Theater</li>
<li>The Haymarket Theatre</li>
<li>The Henry Players</li>
<li>The Highwood Theatre</li>
<li>The Historic Ruffin Theater</li>
<li>The Hive Theatre</li>
<li>The Hub Theatre</li>
<li>The IceHouse Theatre</li>
<li>The Island Theater</li>
<li>The J KC - Jewish Community Center</li>
<li>The James Downing Theatre</li>
<li>The James F. Dean Community Theater</li>
<li>The Joint</li>
<li>The Jones Theater</li>
<li>The Josephine Theatre</li>
<li>The June Bug Center</li>
<li>The Justice Theater Project</li>
<li>The Keegan Theatre</li>
<li>The Kentucky Center for the Performing Arts</li>
<li>The Kimmel Center</li>
<li>The Laboratory Theater of Florida</li>
<li>The Laguna Playhouse</li>
<li>The Lamp Theatre</li>
<li>The LampLight Theatre</li>
<li>The Lamplighters Community Theatre</li>
<li>The Lancaster Playhouse</li>
<li>The Landers Theatre</li>
<li>The Larry Keeton Theatre</li>
<li>The Latta Theatre</li>
<li>The Laurel Mill Playhouse</li>
<li>The Legacy Theatre</li>
<li>The Lexington Players</li>
<li>The Liberty Theatre</li>
<li>The Little Theater</li>
<li>The Little Theatre NSB</li>
<li>The Little Theatre of Winston-Salem</li>
<li>The Little Theatre on the Square</li>
<li>The Living Room Theatre</li>
<li>The Loft Stage at East Ridge High School</li>
<li>The Logos Theatre</li>
<li>The Long Center for the Performing Arts</li>
<li>The Longwood Players</li>
<li>The Lyric Studio Theatre in the Square</li>
<li>The Magik Theatre</li>
<li>The Mann Center</li>
<li>The Mansion Theatre</li>
<li>The Marcelle</li>
<li>The Marco Players</li>
<li>The Marjorie S. Deane Little Theater</li>
<li>The Matchbox Theater</li>
<li>The Merrill Black Box Theater</li>
<li>The Midland Theatre</li>
<li>The Mineral Point Opera House</li>
<li>The Montford Park Players</li>
<li>The Music Center At The Strathmore</li>
<li>The Music Hall, Portsmouth</li>
<li>The Naples Players</li>
<li>The New Depot Players</li>
<li>The New Jewish Theatre</li>
<li>The New Surry Theatre and Performing Arts School</li>
<li>The Nomad Playhouse</li>
<li>The North Fork Community Theatre</li>
<li>The North Fork Community Theatre</li>
<li>The Nutz-n-Boltz Theater Company</li>
<li>The Oak Grove Theater</li>
<li>The Old Theater</li>
<li>The Olde Brick Theatre</li>
<li>The Opera House Players</li>
<li>The Opera House at Enosburg Falls</li>
<li>The Orange Players</li>
<li>The Orpheum Theatre</li>
<li>The Orpheum Theatre</li>
<li>The Orpheum Theatre and Academy of Performing Arts</li>
<li>The Osceola Performing Arts Center</li>
<li>The Overtime Theater</li>
<li>The PApermill Community of Artists</li>
<li>The PIT Loft</li>
<li>The Palace Theater in the Wisconsin Dells</li>
<li>The Palace Theatre</li>
<li>The Paramount Theater</li>
<li>The Park Theater</li>
<li>The Park Theatre</li>
<li>The Pasadena Playhouse</li>
<li>The Peace Center</li>
<li>The Pearl Theater</li>
<li>The Pennsylvania Playhouse</li>
<li>The Performer's Warehouse</li>
<li>The Phoenix Theatre Company</li>
<li>The Players</li>
<li>The Players Centre for Performing Arts</li>
<li>The Players Theatre</li>
<li>The Poet's Den Theater</li>
<li>The Portland Players</li>
<li>The Public Theater</li>
<li>The Public Theater of San Antonio</li>
<li>The Public Theatre</li>
<li>The Purple Masque Theatre</li>
<li>The Raleigh Playhouse and Theatre</li>
<li>The Rehoboth Summer Children's Theatre</li>
<li>The Repertory Theatre - Seaside</li>
<li>The Repertory Theatre of St. Louis</li>
<li>The Ringwald Theater</li>
<li>The Rita</li>
<li>The Ritz Theater - Cinema & Performing Arts</li>
<li>The Ritz Theatre</li>
<li>The Riverside Theatre</li>
<li>The Road Less Traveled Theater Co.</li>
<li>The Road Theatre</li>
<li>The Robert W. Wilson MCC Theater Space</li>
<li>The Robin Theatre</li>
<li>The Rogue Theatre</li>
<li>The Rose Theater</li>
<li>The Rotunda</li>
<li>The Sauk (Hillsdale Community Theatre)</li>
<li>The Secret Theatre</li>
<li>The Sedgwick Theater</li>
<li>The Shadow Players</li>
<li>The Sheldon Vexler Theatre</li>
<li>The Sherman Playhouse</li>
<li>The Sierra Madre Playhouse</li>
<li>The Sky is the Limit Community Theatre</li>
<li>The Smith Center for the Performing Arts</li>
<li>The Smoot Theatre</li>
<li>The Snug Theatre</li>
<li>The Sofia Tsakopoulos Center for The Arts</li>
<li>The Source Theatre Company</li>
<li>The Southern Theater</li>
<li>The Spotlight Playhouse (Theater)</li>
<li>The Spotlight Theatre</li>
<li>The Square Foot Theatre</li>
<li>The Stage Theater</li>
<li>The Stage at Island Park</li>
<li>The Star Theatre</li>
<li>The State Theatre</li>
<li>The Strand</li>
<li>The Strand Theater</li>
<li>The Strand Theatre</li>
<li>The Strand Theatre</li>
<li>The Studio Theatre</li>
<li>The Summit Playhouse</li>
<li>The Sun Theater</li>
<li>The Tennessee Williams Theatre Company of New Orleans</li>
<li>The Texas Theatre</li>
<li>The Theater Bug</li>
<li>The Theater Center</li>
<li>The Theater Company at Fort Lee</li>
<li>The Theater Offensive</li>
<li>The Theater Project</li>
<li>The Theater at North</li>
<li>The Theatre Company of BCS</li>
<li>The Theatre Company of Saugus</li>
<li>The Theatre Downstream</li>
<li>The Theatre Lab</li>
<li>The Underground Collaborative</li>
<li>The Underground Players</li>
<li>The Union County Performing Arts Center</li>
<li>The Unusual Suspects</li>
<li>The Upstart Crow Theatre Company</li>
<li>The VORTEX</li>
<li>The Venue Theatre Company</li>
<li>The Vickie Roberson Theatre</li>
<li>The Victory Theatre Center</li>
<li>The Village Players</li>
<li>The Village Players Theatre</li>
<li>The Village Theatre Guild, Ltd.</li>
<li>The Vineyard Cafe and Dinner Theatre</li>
<li>The Vintage Theatre Company, LLC</li>
<li>The Voices of Hope</li>
<li>The Vortex Theatre</li>
<li>The Warehouse Performing Arts Center</li>
<li>The Warner Theatre</li>
<li>The Weekend Theater</li>
<li>The Weston Friendly Society of the Performing Arts</li>
<li>The White Theatre at the Jewish Community Center</li>
<li>The Wick Theatre</li>
<li>The Wilbury Theatre Group</li>
<li>The Wilson Performing Acts Center</li>
<li>The Wilton Playshop</li>
<li>The Wimberley Playhouse</li>
<li>The Yiddish Art Theater</li>
<li>The Zephyr Theatre</li>
<li>The Ziegfeld Theater</li>
<li>Theater 2020</li>
<li>Theater Alliance</li>
<li>Theater At Monmouth</li>
<li>Theater Bartlesville</li>
<li>Theater Company of Lafayette</li>
<li>Theater For the New City</li>
<li>Theater In the Open</li>
<li>Theater J</li>
<li>Theater Mu Office</li>
<li>Theater On The Edge - Plays In Orlando</li>
<li>Theater Reaching Young People in Schools</li>
<li>Theater Shenandoah Inc</li>
<li>Theater Works</li>
<li>Theater at St. Jean</li>
<li>Theater for Kids at Portland Stage</li>
<li>TheaterWorks</li>
<li>Theaterworks Community Players</li>
<li>Theatre 121</li>
<li>Theatre 29</li>
<li>Theatre 42</li>
<li>Theatre 502 Inc</li>
<li>Theatre 80 St Marks</li>
<li>Theatre 8:15</li>
<li>Theatre 98 Inc</li>
<li>Theatre Acadie</li>
<li>Theatre Albany</li>
<li>Theatre Arlington</li>
<li>Theatre Artists Workshop</li>
<li>Theatre Arts Guild</li>
<li>Theatre Aspen</li>
<li>Theatre At Meydenbauer Center</li>
<li>Theatre Atchison</li>
<li>Theatre B</li>
<li>Theatre Baton Rouge</li>
<li>Theatre Bay Area</li>
<li>Theatre Box</li>
<li>Theatre Bristol</li>
<li>Theatre Buford</li>
<li>Theatre By The Sea</li>
<li>Theatre Cedar Rapids</li>
<li>Theatre Center</li>
<li>Theatre Charlotte</li>
<li>Theatre Communications Group</li>
<li>Theatre Conspiracy</li>
<li>Theatre Coppell</li>
<li>Theatre Downtown</li>
<li>Theatre Exile</li>
<li>Theatre Factory</li>
<li>Theatre Gigante</li>
<li>Theatre Guild</li>
<li>Theatre Guild Valdosta</li>
<li>Theatre Guild of Rockingham</li>
<li>Theatre Guild of Webster Grvs</li>
<li>Theatre Harrisburg</li>
<li>Theatre Horizon</li>
<li>Theatre Huntsville</li>
<li>Theatre III Box Office</li>
<li>Theatre In the Grove</li>
<li>Theatre International Inc</li>
<li>Theatre Knoxville Downtown</li>
<li>Theatre L'Homme Dieu</li>
<li>Theatre Lawrence</li>
<li>Theatre Macon</li>
<li>Theatre Memphis</li>
<li>Theatre Nova</li>
<li>Theatre Off The Square</li>
<li>Theatre On the Bay</li>
<li>Theatre Raleigh</li>
<li>Theatre Rhinoceros</li>
<li>Theatre Rocks!</li>
<li>Theatre Scena</li>
<li>Theatre South Playhouse</li>
<li>Theatre Statesville</li>
<li>Theatre Tallahassee</li>
<li>Theatre Theatre Maui</li>
<li>Theatre Three</li>
<li>Theatre To Go, Inc.</li>
<li>Theatre Tuscaloosa</li>
<li>Theatre Vertigo</li>
<li>Theatre West Summer Repertory</li>
<li>Theatre West Virginia</li>
<li>Theatre West of Lincoln City</li>
<li>Theatre Winter Haven</li>
<li>Theatre Works</li>
<li>Theatre Workshop of Nantucket</li>
<li>Theatre Workshop of Owensboro</li>
<li>Theatre at the Center</li>
<li>Theatre at the Mount</li>
<li>Theatre for a New Audience</li>
<li>Theatre in the Park: Raleigh NC Performing Arts and Entertainment</li>
<li>Theatre in the Round Players Inc</li>
<li>Theatre in the Square</li>
<li>Theatre in the Woods</li>
<li>Theatre of Gadsden</li>
<li>Theatre of Note</li>
<li>Theatre of Western Springs</li>
<li>Theatre of the Imagination</li>
<li>Theatre of the Oppressed NYC (TONYC)</li>
<li>Theatre of the Republic</li>
<li>Theatre on the Ridge</li>
<li>Theatre with a Twist</li>
<li>Theatre-on-the-Hill</li>
<li>TheatreNOW</li>
<li>TheatreSquared</li>
<li>TheatreWorks</li>
<li>TheatreWorks Florida-Theatre & Studio</li>
<li>TheatreWorks New Milford</li>
<li>Theatrikos Theatre Company</li>
<li>Thespian Hall</li>
<li>Thibodaux Playhouse</li>
<li>Thinking Cap Theatre</li>
<li>Third Avenue Playhouse</li>
<li>Third Life Studio</li>
<li>Third Rail Repertory Theatre</li>
<li>Thomas Jefferson Community Theatre</li>
<li>Thomasville On Stage & Co</li>
<li>Three Bone Theatre</li>
<li>Three Notch Theatre</li>
<li>Three Rivers Community Players</li>
<li>Threshold Repertory Theatre</li>
<li>Throckmorton Theatre</li>
<li>Thrown Stone</li>
<li>Thunder Bay Theatre</li>
<li>Thunder River Theater Co</li>
<li>Tibbits Opera House</li>
<li>Tidewater Players</li>
<li>Tift Theatre</li>
<li>Tillotson Center-Community Heritage, Visual & Performing Arts</li>
<li>Timberlane Performing Arts Center</li>
<li>Time Community Theater</li>
<li>TimeLine Theatre Company</li>
<li>Timpanogos Valley Theater</li>
<li>Tin Roof Productions</li>
<li>Tipping Point Theatre</li>
<li>Tipton Community Theatre</li>
<li>Titusville Playhouse Inc</li>
<li>Toby's Dinner Theatre</li>
<li>Toledo Repertoire Theatre</li>
<li>Topeka Civic Theatre</li>
<li>Totem Pole Playhouse</li>
<li>Touchstone Theatre</li>
<li>Tower Theatre</li>
<li>Town & Country Players</li>
<li>Town & Gown Community Theatre</li>
<li>Town & Gown Players</li>
<li>Town Hall Arts Center</li>
<li>Town Hall Theater</li>
<li>Town Hall Theatre</li>
<li>Town Hall Theatre Co-Lafayette</li>
<li>Town Players</li>
<li>Town Players Little Theatre</li>
<li>Town Players of New Canaan</li>
<li>Town Theatre</li>
<li>Towne Centre Theatre Ticket</li>
<li>Township Theatre Group</li>
<li>Trackside Theater</li>
<li>TrapDoor Ensemble</li>
<li>Treasure Coast Theatre</li>
<li>Tred Avon Players</li>
<li>Tree City Playhouse</li>
<li>Trenton Theatre Office</li>
<li>Tri-Lakes Community Theatre</li>
<li>Triad Stage</li>
<li>Triboro Youth Theatre</li>
<li>Trike Theatre</li>
<li>Trillium Performing Arts</li>
<li>Trinity Repertory Company</li>
<li>Trollwood Performing Arts School</li>
<li>Troy Civic Theatre Inc</li>
<li>Truckee Community Theater</li>
<li>Trustus Theatre</li>
<li>Tryps</li>
<li>Tulsa Little Theatre</li>
<li>Tulsa Spotlight Theatre</li>
<li>Tupelo Community Theater</li>
<li>Turlock Community Theatre</li>
<li>Tuscany Theatre</li>
<li>Tuskegee Repertory Theatre Inc</li>
<li>Twilight Theater Company</li>
<li>Twin Beach Players</li>
<li>Twin City Players Reservations</li>
<li>Twin Lakes Playhouse</li>
<li>Two Planks Theater</li>
<li>Tybee Post Theater</li>
<li>Tyler Civic Theatre</li>
<li>Tyrone Community Players</li>
<li>UALR Center for Performing Arts</li>
<li>UMKC Theatre</li>
<li>UMass Theatre Guild</li>
<li>UNLV Theatre Department</li>
<li>URI Theatre Department</li>
<li>USM Theater Box Office</li>
<li>UW Center for Civic Engagement</li>
<li>UWM PSOA Helene Zelazo Center for the Performing Arts</li>
<li>Ukiah Players</li>
<li>Ulster Performing Arts Center</li>
<li>Un-Common Theatre Company</li>
<li>Unadilla Theatre</li>
<li>Under St Marks Theater</li>
<li>Unicorn Theatre</li>
<li>Union Community Theatre</li>
<li>Union Point Theatre</li>
<li>Union Street Players</li>
<li>University Of Arkansas Theatre</li>
<li>University Theatre</li>
<li>University of Central Arkansas</li>
<li>University of Colorado Boulder Department of Theatre & Dance</li>
<li>University of Florida Performing Arts</li>
<li>University of Kentucky Opera Theatre</li>
<li>University of Missouri Department of Theatre</li>
<li>University of Montana - Dennison Theatre</li>
<li>University of South Carolina :Department of Theatre and Dance</li>
<li>University of Tampa Theater Department</li>
<li>University of Wisconsin-Manitowoc Theatre</li>
<li>Up and Coming Theatre Company</li>
<li>Upper Darby Performing Arts Center</li>
<li>Upson-Lee Fine Arts Center</li>
<li>Upstage Theatre & Performing</li>
<li>Upstream Theater</li>
<li>Uptown Theater in Grand Prairie</li>
<li>Uptown! Knauer Performing Arts Center</li>
<li>Urban Stages</li>
<li>Urbanite Theatre</li>
<li>Ursuline Arts Center</li>
<li>Utah Children's Theatre</li>
<li>Utah Children's Theatre School</li>
<li>Utah Festival Opera & Musical Theatre</li>
<li>Utah Repertory Theater</li>
<li>Utah Shakespeare Festival</li>
<li>Utah Valley University Theatre Department</li>
<li>V. Earl Dickinson Theater</li>
<li>VSU Theatre & Dance</li>
<li>Vagabond Players Inc</li>
<li>Valatie Community Theatre</li>
<li>Vallejo Music Theatre</li>
<li>Valley Performing Arts</li>
<li>Valley Players Theater</li>
<li>Valley Repertory Theatre</li>
<li>Valley Youth Theatre</li>
<li>Van Buren Fine Arts Center</li>
<li>Van Wert Civic Theatre Inc</li>
<li>Vanderbilt University Theatre</li>
<li>Vanilla Box Productions</li>
<li>Vault Theater</li>
<li>Venice Performing Arts Center</li>
<li>Venice Theatre</li>
<li>Verge Theatre</li>
<li>Vermillion Players</li>
<li>Vermont Family Theater Inc</li>
<li>Vermont Musical Theatre Academy</li>
<li>Vermont Stage</li>
<li>Vermont Youth Theater</li>
<li>Vernal Theatre: LIVE</li>
<li>Vero Beach High School Performing Arts Center</li>
<li>Vero Beach Theatre Guild</li>
<li>Verona Area Community Theater</li>
<li>Verona Area Community Theatre</li>
<li>Very Little Theatre</li>
<li>Very Merry Theatre</li>
<li>Vicksburg Theatre Guild</li>
<li>Victoria Theatre Association</li>
<li>Victory Gardens Theatre</li>
<li>Vienna Theatre Company</li>
<li>Village Players</li>
<li>Village Players Inc</li>
<li>Village Players of Ft Thomas</li>
<li>Village Players of Hatboro</li>
<li>Village Playhouse</li>
<li>Village Square Theatre</li>
<li>Village Theater at Cherry Hill</li>
<li>Village Theatre Everett KIDSTAGE</li>
<li>Village Theatre, Everett</li>
<li>Village Theatre, Issaquah</li>
<li>Village Theatre-First Stage</li>
<li>Villagers Theatre</li>
<li>Vince Carney Community Theatre</li>
<li>Vinegar Hill Music Theatre</li>
<li>Vint Hill Theater On the Green</li>
<li>Vint Hill Village Green Community Center</li>
<li>Vintage Theatre Productions</li>
<li>Virago Theatre of Alameda</li>
<li>Virginia City Opera House</li>
<li>Virginia City Players</li>
<li>Virginia Samford Theatre</li>
<li>Virginia Stage Company</li>
<li>Virginia Theater</li>
<li>Virginia Western Theatre</li>
<li>Visionbox</li>
<li>Vital Theatre Company - Offices</li>
<li>Vive Les Arts Theatre</li>
<li>Vokes Players</li>
<li>Vokes Theatre</li>
<li>Voorhees Theatre Company</li>
<li>W E Scott Theatre</li>
<li>WAM Theatre</li>
<li>WMC Productions Community Theater</li>
<li>WOLFPACK THEATRE COMPANY</li>
<li>WVSU Capitol Center Theater</li>
<li>WYO Theater</li>
<li>Waco Children's Theatre</li>
<li>Waco Civic Theatre</li>
<li>Waimea Community Theatre</li>
<li>Wakefield Repertory Theatre</li>
<li>Waldo Theatre</li>
<li>Walnut Street Theater</li>
<li>Walnut Street Theatre</li>
<li>Walpole Children's Theatre</li>
<li>Walpole Footlighters</li>
<li>Wang Theatre Stage Door</li>
<li>Warehouse Living Arts Center Theatre & Gallery</li>
<li>Warehouse Theatre</li>
<li>Warehouse Theatre</li>
<li>Warner Robins Little Theatre</li>
<li>Warren Miller Performing Arts Center</li>
<li>Warsaw Federal Incline Theater</li>
<li>Wasatch Theatre Co</li>
<li>Washington Center for the Performing Arts</li>
<li>Washington Community Theater</li>
<li>Washington Community Theatre</li>
<li>Washington County Playhouse Dinner Theater and Children's Theater</li>
<li>Washington Little Theater Co</li>
<li>Washington Stage Guild</li>
<li>Washington Street Players</li>
<li>Waterbury Festival Playhouse</li>
<li>Waterfront Playhouse</li>
<li>Waterloo Community Playhouse</li>
<li>Watertown Children's Theatre</li>
<li>Watertown Players Theater</li>
<li>Waterville Opera House</li>
<li>Waterworks Players Inc</li>
<li>Waukesha Civic Theatre</li>
<li>Wausau Community Theatre</li>
<li>Way Off Broadway Community Players</li>
<li>Wayne Densch Performing Arts Center</li>
<li>Wayne Theatre</li>
<li>Weathervane Playhouse</li>
<li>Weathervane Theatre</li>
<li>Weber Center for the Performing Arts</li>
<li>Webster City Community Theatre</li>
<li>Weirs Community Center</li>
<li>Weiser Little Theater</li>
<li>Wellesley Theatre Project</li>
<li>Wellmont Regional Center for the Performing Arts</li>
<li>Wells Fargo Auditorium</li>
<li>Wells Fargo Pavilion</li>
<li>Wells Theatre</li>
<li>West Arundel Creative Arts</li>
<li>West Chester & Barley Sheaf</li>
<li>West Coast Players</li>
<li>West End Players Guild</li>
<li>West End Studio Theatre</li>
<li>West Hudson Arts & Theater Company</li>
<li>West Performing Arts Center</li>
<li>West Side Theatre</li>
<li>West Side Theatre Foundation</li>
<li>West T Hill Community Theatre</li>
<li>West Valley Light Opera</li>
<li>West Virginia Public Theatre</li>
<li>Westbeth Theatre Center</li>
<li>Westborough Players Club</li>
<li>Westbrook Performing Arts Center</li>
<li>Westchester Playhouse</li>
<li>Westcoast Black Theatre Troupe</li>
<li>Western Nevada Musical Theatre Company</li>
<li>Westfield Community Players</li>
<li>Westfield Theatre Group</li>
<li>Westfield Woman's Club</li>
<li>Westminster Community Playhouse</li>
<li>Westmoreland Players Theatre</li>
<li>Weston Playhouse</li>
<li>Weston Playhouse at Walker Farm</li>
<li>Westport Community Theatre Inc</li>
<li>Westport Country Playhouse</li>
<li>Westside Players</li>
<li>Westside Theatre</li>
<li>Wharton Center for Performing Arts</li>
<li>Wheaton College Arena Theater</li>
<li>Wheaton Drama Inc</li>
<li>Wheelock Family Theatre at Boston University</li>
<li>Whidbey Playhouse Inc</li>
<li>Whitefish Theatre Co</li>
<li>Whitney Center for the Arts</li>
<li>Whodunit</li>
<li>Whole Backstage Theatre</li>
<li>Wichita Community Theatre Inc</li>
<li>Wichita Theater Performing Art Centre</li>
<li>Wild Project</li>
<li>Wild Swan Theater Performances</li>
<li>Wildwood Park for the Arts</li>
<li>Williams County Comm Theater</li>
<li>Williams Street Rep</li>
<li>Williamsburg Players Inc</li>
<li>Williamston Theatre</li>
<li>Williamstown Theatre Festival</li>
<li>Willie Pigg Auditorium</li>
<li>Willits Community Theatre</li>
<li>Willow Theatre at Sugar Sand Park</li>
<li>Wilmington Drama League</li>
<li>Wilson Center at Cape Fear Community College</li>
<li>Wimberley Players Inc Box Office</li>
<li>Winder Cultural Arts Center</li>
<li>Windham Center Stage Theater</li>
<li>Windham Theatre Guild</li>
<li>Winding River Players Inc</li>
<li>Windsor Community Playhouse</li>
<li>Windsor Jesters</li>
<li>Windsor Theatre Development Corporation</li>
<li>Windy City Playhouse</li>
<li>Winfield Community Theater</li>
<li>Winneshiek Playhouse</li>
<li>Winnipesaukee Playhouse</li>
<li>Winston-Salem Theatre Alliance</li>
<li>Winter Park Play House</li>
<li>Winter Springs Performing Arts</li>
<li>Winters Theatre Company</li>
<li>Winthrop Playmakers</li>
<li>Wisconsin Rapids Community Theater</li>
<li>Wise Fool New Mexico</li>
<li>Wofford Theatre</li>
<li>Wolf Pack Theater</li>
<li>Wonderland Performing Arts</li>
<li>Woodford Theatre</li>
<li>Woodinville Repertory Theatre</li>
<li>Woodland Hills Community Theatre</li>
<li>Woodlawn Theatre</li>
<li>Woodman Family Community & Performance Center</li>
<li>Woodminster Amphitheater</li>
<li>Woods Hole Theater Co</li>
<li>Woodstock Playhouse</li>
<li>Woolfe Street Playhouse</li>
<li>Woolly Mammoth Theatre Company</li>
<li>Words Players Theatre</li>
<li>Working Theater</li>
<li>Workshop Players</li>
<li>Workshop Theatre of South Carolina</li>
<li>Writers Theatre</li>
<li>Writers Theatre of New Jersey</li>
<li>Wyandotte Community Theatre</li>
<li>Wyly Theatre</li>
<li>Xavier Fine Arts Theatre</li>
<li>Xenia Area Community Theater, Inc.</li>
<li>Yale Repertory Theatre</li>
<li>Yardley Hall in the Carlsen Center</li>
<li>Ye Olde Opera House</li>
<li>Yellow Rose Dinner Theater</li>
<li>Yellow Tree Theatre</li>
<li>Yorba Linda Spotlight Theater Company</li>
<li>York Theatre Company</li>
<li>Young Actors Playhouse</li>
<li>Young Actors Theatre</li>
<li>Young Shakespeare Players</li>
<li>Youngstown Playhouse & Youth Theatre</li>
<li>Youth Performance Company - Performance</li>
<li>Youth Theatre Northwest</li>
<li>Youth Theatre at the U</li>
<li>Yucca Theatre</li>
<li>ZACH Theatre</li>
<li>ZAO Theatre</li>
<li>Zanesville Community Theatre</li>
<li>Zeiders American Dream Theater</li>
<li>Zeiterion Theatre</li>
<li>Zeitgeist Stage Company</li>
<li>Zella Fry Theatre</li>
<li>actsharpsville</li>
<li>anderson theater department</li>
<li>centerstage theatre</li>
<li>forestgate community theatre</li>
<li>freeFall Theatre</li>
<li>fremont street theater</li>
<li>iD Studio Theater</li>
<li>iDiOM Theater</li>
<li>iTheater Collaborative (iTC)</li>
<li>judson theatre co</li>
<li>merlin players</li>
<li>michigan community theatre association</li>
<li>ms children's music theater</li>
<li>off the WALL productions</li>
<li>old world theatre co.</li>
<li>the dance complex</li>
<li>the mines theatre</li>
<li>theatreWashington</li>
<li>viBe Theater Experience</li>
<li>﻿Academy of Children's Theatre</li>
</ul>`;
